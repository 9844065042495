/**
 * @author  XuHongli
 * @date  2022/12/5 11:47
 * @version 1.0
 * @description 添加分组函数式组件
 */
import Vue from 'vue'
import AddGroupModal from '@/components/FunctionComponents/AddGroupModal/AddGroupModal'

/**
 * 添加分组组件
 * @param props { {ids: Array<string>, handleOk: Function} }
 * @return {VNode}
 * @private
 */
export const _AddGroupModal = (props) => {
  // 新建挂载节点 (Node)
  const mountNode = document.createElement('div')
  document.body.appendChild(mountNode)
  // Vue.extend传递组件选项，返回一个组件构造函数
  const constructor = Vue.extend({
    render(h) {
      return h(AddGroupModal, { props: { ...props, visible: true } })
    }
  })
  // 相当于获取当前Vue实例 Vue.extend 具体看Vue.js文档
  const vm = new constructor().$mount()

  const comp = vm.$children[0]

  // 将销毁方法传递到组件内部
  comp.remove = () => {
    document.body.removeChild(mountNode)
    vm.$destroy()
  }

  // 获取组件的DOM，将其挂载到body上
  document.body.appendChild(vm.$el)
}
