<template>
  <div class="shop-navigation">
    <div class="sn-centert">
      <div class="sn-centert-logo">
        <div class="img"></div>
        这不是一句广告语
      </div>
      <div class="sn-centert-list">
        <ul id="nav-l-en">
          <li class="t-Protection">
            <a href="#"> 免费加入 </a>
          </li>
          <li class="t-Divider"></li>
          <li class="t-Protection">
            <a href="#"> 登录 </a>
          </li>
          <li class="t-Divider"></li>
          <li class="t-Merchant">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                我们vllshop
                <a-icon type="heart" theme="filled" two-tone-color="#fff" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;">我的商城</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">轻松开店</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">帮助中心</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">联系客服</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </li>
          <li class="t-Divider"></li>
          <li class="t-Merchant">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                帮助
                <a-icon type="heart" theme="filled" two-tone-color="#fff" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;">我的商城</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">轻松开店</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">帮助中心</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">联系客服</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </li>
          <li class="t-Divider"></li>
          <li class="t-Merchant">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                英语
                <a-icon type="heart" theme="filled" two-tone-color="#fff" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;">我的商城</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">轻松开店</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">帮助中心</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">联系客服</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </li>
        </ul>
        <div class="car">
          <div>
            <!--            <img src='@/assets/icon/car.png' alt=''>-->
            <span>0</span>
          </div>
          <a-icon type="heart" theme="filled" two-tone-color="#fff" style="color: #fff;margin-left: 8px" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopNavigation'
}
</script>

<style lang='scss' scoped>
.shop-navigation {

  .sn-centert {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sn-centert-logo {
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 12px;
      text-align: left;
      color: #fff;
      display: flex;
      align-items: center;

      .img {
        width: 80px;
        height: 20px;
        display: inline-block;
        background: #4fabff;
        margin-right: 8px;
      }
    }
  }

  .sn-centert-list {
    display: flex;
    align-items: center;

    ul {
      //float: right;
      //display: block;
      //clear: both;
      margin: 0;
      display: flex;
      align-items: center;
    }

    li {
      line-height: 30px;
      position: relative;
    }

    li.t-coupon {
      padding: 0 0 0 18px;
      height: 24px;
      margin-top: 3px;
      display: block;

      a {
        float: left;
        height: 24px;
        padding-right: 40px;
      }

      span {
        float: left;
        padding: 0 0 0 15px;
        line-height: 24px;
        font-size: 11px;
        color: $color-orange;
      }
    }

    li.t-Divider {
      margin: 8px 8px;
      height: 16px;
      width: 1px;
      background: #fff;
    }

    .car {
      margin-left: 32px;
      display: flex;
      align-items: center;

      //img {
      //  width: 56px;
      //  height: 44px;
      //}

      div {
        width: 42px;
        height: 30px;
        position: relative;
        background: url('../../../assets/icon/car.png') no-repeat center -6px;
        //background-size: 34px 30px;
        background-size: 120%;
        span {
          display: inline-block;
          width: 16px;
          height: 18px;
          text-align: center;
          line-height: 18px;
          background: #e24308;
          position: absolute;
          top: -4px;
          left: 18px;
          color: white;
          border-radius: 50%;
        }
      }

    }
  }
}
</style>
