/**
 * @author  XuHongli
 * @date  2022/7/7 17:05
 * @version 1.0
 * @description Cookie 方法合集
 */
import Cookies from 'js-cookie'
import config from '@/config/defaultSettings'
/** Token 键名  */
export const tokenKey = 'Access_Token'

// 1.以用户手机缓存token
export const getToken = () => Cookies.get(tokenKey)
export const setToken = (token) => Cookies.set(tokenKey, token, { expires: config.token_expires })
export const removeToken = () => Cookies.remove(tokenKey)
