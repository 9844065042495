<template>
  <div class="bg-white sticky">
    <div class="head-Warp">
      <div class="flex-row" style="flex: 1; padding-right: 80px;" @mouseleave.stop="isSelect = false">
        <div :style="{ background: `url(${$tp().OPEN_LOGO_COL}) no-repeat` }" class="head-logo head-logo-col"
          @click="$router.push('/')">
          <a href="#"></a>
        </div>

        <div class="flex-row flex-col-center" style="margin-left: 45px;">
          <div>
            <a :class="['title-line', ' mr-20', { 'active': titleLinkIndex === 1 }]" href="javascript:void(0)"
              @click="$router.push('/user/userCenter')"> 个人中心 </a>
            <a-dropdown>
              <a :class="['title-line', { 'active': titleLinkIndex === 2 }]" @click="e => e.preventDefault()">
                我的设置 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-if="$tp().IS_OPEN != 1">
                  <a :class="['title-line', { 'childer_active': titleLinkIndex === 2 && boolen === 1 }]"
                    href="javascript:void(0)" @click="$router.push('/user/authentication')">账号信息</a>
                </a-menu-item>
                <a-menu-item v-if="$tp().IS_OPEN != 1">
                  <a :class="['title-line', { 'childer_active': titleLinkIndex === 2 && boolen === 2 }]"
                    href="javascript:void(0)" @click="$router.push('/user/security')">账号安全</a>
                </a-menu-item>
                <a-menu-item>
                  <a :class="['title-line', { 'childer_active': titleLinkIndex === 2 && boolen === 3 }]"
                    href="javascript:void(0)" @click="$router.push('/user/development')">API对接</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>

        <div class="head-SearchBox">

          <div class="head-Search-inner">
            <div class="search">
              <input ref="inputRef" v-model="search" class="input_focus" placeholder="请输入关键词" style="padding-left: 15px;"
                type="text" @focus="onFocus" @input="onFocus" @mouseover="show" @keyup.enter="get_search">
              <div class="btn" @click="get_search">
                <i class="el-icon-search" style="font-size:22px;margin-top: 11px;"></i>
              </div>
            </div>
            <div v-if="showKeywords" class="flex-row">
              <span v-for="(item, index) in keywords" :key="index"
                :style="{ color: active === index ? 'red' : '#999999' }" :to="`/search?searchName=${item.name}`"
                class="mx-5 my-10 text_Color" @click="onRouterLink(item, index)">{{ item.name }}</span>

            </div>
            <div v-show="isSelect" ref="selecterRef" class="selecter" @mouseleave="onMouseleave">
              <div class="selecter-roqi">
                <div style="cursor: default;">历史搜索</div>
                <div style="cursor: pointer;" @click="empty">清空</div>
              </div>
              <span v-for="(item, index) in historyList" :key="index" @click="goSearchDetail(item)">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <!--   原本宽度为320   -->
      <div class="flex-row head-use"
        style="width: 160px;height: 39px; margin-top: 5px; justify-content: space-around;margin-right: 114px;">
        <a-button class="favoriteBtn" plain type="info" @click="$router.push({ path: '/user/favorite' })">我的收藏</a-button>
      </div>
    </div>
    <HomeGoodsCategory v-show="showKeywords"></HomeGoodsCategory>

  </div>
</template>

<script>
import IconJoin from '@/Layouts/Default/Header/IconJoin/IconJoin'
import IconFavorites from '@/Layouts/Default/Header/IconFavorites/IconFavorites'
import IconViewed from '@/Layouts/Default/Header/IconViewed/IconViewed'
import IconShopCart from '@/Layouts/Default/Header/IconShopCart/IconShopCart'
import { keywords } from '@/Layouts/Default/Header/keywords'
import clonedeep from 'lodash.clonedeep'
import { mapGetters } from 'vuex'
import HomeGoodsCategory from '@/views/Home/HomeGoodsCategory/HomeGoodsCategory'

/**
 * @author  XuHongli
 * @date  2022/7/12 15:34
 * @version 1.0
 * @description 头部
 */
export default {
  name: 'UserHeadWarp',
  components: { IconShopCart, HomeGoodsCategory, IconViewed, IconFavorites, IconJoin },
  data() {
    return {
      titleLinkIndex: '',// 1 首页 2 我的设置
      active: 0,
      keywords,
      historyList: [],  //历史搜索记录，存本地
      isSelect: false,
      search: '',  //搜索的内容
      showFavorites: false,
      showViewed: false,
      showShopCart: false,
      // 监听器事件
      Listener: null,
      // 显示关键词列表
      showKeywords: false,
      // 不是个人中心/我的设置
      boolen: null
    }
  },
  watch: {
    '$route': {
      handler(n, o) {
        this.search = n.query.searchName
        this.titleLinkIndex = n.path === '/user/userCenter' ? 1 : null
        if (n.path === '/user/authentication') {
          this.titleLinkIndex = 2
          this.boolen = 1
        } else if (n.path === '/user/security') {
          this.titleLinkIndex = 2
          this.boolen = 2
        } else if (n.path === '/user/development') {
          this.titleLinkIndex = 2
          this.boolen = 3
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['webKeyword'])
  },
  created() {

    // 把搜索框的关键字去掉
    window.rout = () => {
      this.search = ''
    }
  },
  beforeDestroy() {
    // https://developer.mozilla.org/zh-CN/docs/Web/API/EventTarget/removeEventListener
    window.removeEventListener('scroll', this.Listener, { passive: false })
  },
  mounted() {
    //如果本地存储的数据historyList有值，直接赋值给data中的historyList
    if (JSON.parse(localStorage.getItem('historyList'))) {
      this.historyList = JSON.parse(localStorage.getItem('historyList'))
    }
  },
  methods: {
    // 滚动监听注册
    scrollListener() {
      this.Listener = () => {
        window.requestAnimationFrame(() => {
          this.showKeywords = window.scrollY === 0
        })
      }
      window.addEventListener('scroll', this.Listener || null, { passive: false })
    },
    onRouterLink(item, index) {
      this.active = index
      this.$router.push({
        path: `/search?searchName=${item.name}`
      })
    },
    get_search() {
      console.log(this.search, 'this.search')
      this.isSelect = false
      if (this.search === '' || this.search === undefined) {
        this.$message.warning('请输入搜索内容')
        return false
      } else {
        // 没有搜索记录，把搜索值unshift进数组首位，存入本地
        if (!this.historyList.includes(this.search) && this.historyList.length < 5) {
          this.historyList.unshift(this.search)
          localStorage.setItem('historyList', JSON.stringify(this.historyList))
        } else {
          //有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
          let i = this.historyList.indexOf(this.search)
          this.historyList.splice(i, 1)
          this.historyList.unshift(this.search)
          localStorage.setItem('historyList', JSON.stringify(this.historyList))
        }

        console.log(this.$route)


        //跳转到搜索结果页
        this.$router.push({
          name: 'Search',
          query: Object.assign(clonedeep(this.$route.query), {
            searchName: this.search,
          }),
        })
        //  搜索事件触发接口调用
        if (window.a) window.a()
      }
    },
    //点击历史搜索，跳转搜索结果页
    goSearchDetail(title) {
      this.isSelect = false
      this.$router.push({
        path: '/search',
        query: {
          searchName: title,
        },
      })
    },
    //清空历史搜索记录
    empty() {
      this.isSelect = false
      this.$message({
        duration: 1000,
        message: '清空历史搜索成功',
        type: 'success'
      })
      localStorage.removeItem('historyList')
      this.historyList = []
    },

    show() {
      this.isSelect = false
    },
    onFocus(e) {
      this.historyList.length == 0 ? this.isSelect = false : this.isSelect = true
      // this.$refs.selecterRef.style.display = 'block'
    },
    onMouseleave() {
      this.isSelect = false
      // this.$refs.selecterRef.style.display = 'none'
    },
  },
}
</script>

<style lang="scss" scoped>
.title-line {
  position: relative;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #df3028;
  }

  &.active {
    color: #c81623;
    font-weight: 600;
  }

  &.childer_active {
    color: #c81623;
    font-size: 16px;
  }
}

.favoriteBtn {
  border-radius: 20px;
  width: 104px;
  background: linear-gradient(to right, rgb(250, 141, 88), rgb(229, 76, 40));
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.flex-row .text_Color:hover {
  color: red !important;
  cursor: pointer;
}

.head-Search-inner ::v-deep .ant-input {
  height: 48px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.head-Warp {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 100px;
  position: relative;
}

.head-logo,
.head-logo-col {
  // border: 1px solid red;
  cursor: pointer;
  left: 0;
  width: 184px;
  height: auto;
  position: relative;
  background-size: cover !important;

  &.head-logo-col {
    background-size: contain !important;
  }

  span {
    display: block;
    font-size: 11px;
    font-family: Arial, fangsong;
    color: #666;
    position: absolute;
    left: 97px;
    top: 40px;
    line-height: 12px;
  }

}

.head-Warp .head-SearchBox {
  margin-left: 100px;
  padding: 1px;
  margin-top: 5px;
  width: 300px;
  //margin-left: auto;
  height: 35px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background: #f6f6f6;

  .search {
    position: relative;
    display: flex;
    border: 2px solid red;

    .icon {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 25px;
      transform: translate(-50%, -50%);
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .btn {
      position: absolute;
      width: 70px;
      height: 44px;
      // border: 1px solid red;
      background-color: red;
      color: #fff;
      right: -72px;
      text-align: center;
      line-height: 30px;
      top: 50%;
      transform: translateY(-50%);
      // border-bottom-right-radius: 5px;
      // border-top-right-radius: 5px;
      cursor: pointer;
    }
  }

  input {
    width: 528px;
    height: 40px;
    border-radius: 10px;
    background: #f6f6f6;
    outline: none;
    border: none;
    padding-left: 45px;
  }

  .selecter {
    width: 490px;
    position: absolute;
    top: 63px;

    line-height: 27px;
    background: #fefefe;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
    padding: 0 0 0 10px;

    .selecter-roqi {
      width: 470px;
      display: flex;
      justify-content: space-between;
    }

    span {
      display: inline-block;
      font-size: 13px;
      margin-right: 15px;
      cursor: pointer;
    }
  }


}

.img {
  cursor: pointer;
  width: 42px;
  height: 42px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1270px) {
  .head-Warp .head-SearchBox {
    width: 45%;
  }

  .shopCartText {
    display: none;
  }
}
</style>
