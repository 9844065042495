<template>
  <div class="flex-column flex-col-center copyright">
    <p><span>版权所有 {{ webKeyword.OPEN_COM_NAME }}</span><a href="http://beian.miit.gov.cn" target="_blank">  {{ webKeyword.OPEN_RECORD_NO }}</a></p>
    <p> Copyright © 2016-2023 {{ webKeyword.OPEN_DOMAIN }} </p>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

/**
 * @author  XuHongli
 * @date  2022/11/14 14:28
 * @version 1.0
 * @description
 */

export default {
  name: 'Copyright',
  computed: {
    ...mapGetters(['webKeyword'])
  },
}
</script>

<style lang="scss" scoped>
.copyright{
  >p,a {
    color: #999;
    font-size: 12px;
    text-align: center;
  }
}
</style>
