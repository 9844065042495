<template>
  <div id="layout-default" :class="[layoutClass]" :style="{background: `url(${adBanner}) no-repeat center top`}" class="layout-default">
    <!--  广告栏Banner  -->
    <div v-if="bannerList.imgUrl">
      <div
        :style="bannerList.imgUrl ? `background-image: url(${bannerList.imgUrl})` : 'https://file.jingpingo.com/supply/material/9fd6c484-b080-405b-9e53-d74f5ab820fa.png'"
        class="drainage-banner"
        @click="onJumpUrl"
      >
      </div>

    </div>
    <TopWarp></TopWarp>
    <HeadWarp></HeadWarp>
    <FavoritePanelDrawer></FavoritePanelDrawer>
    <router-view style="padding-bottom: 20px;" />
    <Footer></Footer>
  </div>
</template>

<script>
import TopWarp from '@/Layouts/components/TopWarp'
import HeadWarp from '@/Layouts/Default/Header/HeadWarp'
import Footer from '@/Layouts/components/Footer'
import FavoritePanelDrawer from '@/Layouts/components/FavoritePanelDrawer'
import rightJustify from '@/Layouts/components/rightJustify'
import {supplyBanner} from '@/api/Category'
/**
 * @author  XuHongli
 * @date  2022/7/11 14:40
 * @version 1.0
 * @description 默认电脑端布局
 */

export default {
  name: 'DefaultLayout',
  components: { FavoritePanelDrawer, Footer, HeadWarp, TopWarp,rightJustify },
  data() {
    return {
      adBanner: '',
      bannerList:{},
    }
  },
  created() {
    this.getBanner()
  },
  methods:{
    onJumpUrl() {
      if (this.bannerList.jumpUrl) window.open(this.bannerList.jumpUrl)
    },
    getBanner(){
      supplyBanner({type:2}).then(res => {
        if (res.data.length) this.bannerList = res.data[0]
      })
    }
  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass () {
      return this.$route.meta.layoutClass
    },
  },
}
</script>

<style scoped>
.layout-default{
  min-height: 100%;

}
.drainage-banner{
  width: 100%;
  height: 70px;
  cursor: pointer;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  /*background-image: url("https://file.jingpingo.com/supply/material/9fd6c484-b080-405b-9e53-d74f5ab820fa.png");*/

}
.drainage-banner a{
    display: block;
    width: 100%;
    height: 60px;
}
</style>
