import Vue from 'vue'
import {
  Button,
  MenuItem ,
  Tag,Form,
  FormItem,
  Col,
  Row,
  Image,
  InputNumber,
  Message,
  Radio,
  Tabs,
  TabPane,
  RadioButton,
  MessageBox,
  RadioGroup,
  Switch,
  Carousel,
  CarouselItem,
  Popover,
  Avatar,
  Input,
  Icon,
  Table,
  TableColumn,
  Dialog,
  Select,
  Option,
  DatePicker,
  Breadcrumb,
  BreadcrumbItem,
  Pagination
} from 'element-ui'

Vue.component(Breadcrumb.name,Breadcrumb)
Vue.component(BreadcrumbItem.name,BreadcrumbItem)

Vue.component(Pagination.name,Pagination)
Vue.component(DatePicker.name,DatePicker)
Vue.component(Dialog.name,Dialog)
Vue.component(Select.name,Select)
Vue.component(Option.name,Option)

Vue.component(TableColumn.name,TableColumn)
Vue.component(InputNumber.name, InputNumber)
Vue.component(TabPane.name, TabPane)
Vue.component(Tabs.name, Tabs)
Vue.component(Button.name, Button)
Vue.component(MenuItem.name, MenuItem)
Vue.component(Tag.name, Tag)
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(Col.name, Col)
Vue.component(Row.name, Row)
Vue.component(Image.name, Image)
Vue.component(Radio.name, Radio)
Vue.component(RadioButton.name, RadioButton)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Switch.name, Switch)
Vue.component(Table.name, Table)
Vue.component(Carousel.name, Carousel)
Vue.component(Popover.name, Popover)
Vue.component(Input.name, Input)
Vue.component(Icon.name, Icon)
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Avatar.name, Avatar)
Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$alert = MessageBox.alert


