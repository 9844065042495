<template>
  <div class="help-menu">
    <ul
      v-for="(module, index) in footerOptions"
      :key="index"
      class="help-module"
    >
      <p class="title">{{ module.title }}</p>
      <li v-for="(item, i) in module.item" :key="`${index}-${i}`">
        <router-link
          :class="{active: item.link === $route.path}"
          :to="item.link"
          v-if="item.link.indexOf('http') === -1">{{ item.name }}</router-link>
        <a target="_blank" :href="item.link" v-else>{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>/**
 * @author  XuHongli
 * @date  2022/11/15 9:28
 * @version 1.0
 * @description 帮助中心菜单
 */
import { footerOptions } from '@/Layouts/Help/FooterOptions'
export default {
  name: 'HelpMenu',
  data() {
    return {
      footerOptions
    }
  },
}
</script>

<style scoped lang="scss">
$helpMenuHeight : 1100px;
  .help-menu {
    display: flex;
    flex-direction: column;
    height: $helpMenuHeight;
    width: 200px;
    padding: 40px 20px;
    background: #fff;
    border: 1px solid #dadada;
    min-height: 760px;
    text-align: left;
    line-height: 2.5;
  }
  .help-module {
    padding-left: 0;
    margin-bottom: 20px;
    .title{
      font-size: 15px;
      color: #333;
      font-weight: 700;
    }
    .item{
      font-size: 14px;
      cursor: pointer;
      display: block;
      color: #999;
      text-decoration: none;
    }
    a.active{
      color: #096DD9;
    }
  }

</style>
