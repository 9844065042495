/**
 * @author  XuHongli
 * @date  2022/9/5 16:25
 * @version 1.0
 * @description
 */
import request from '@/utils/axios'

/**
 * 获取用户订单各个状态数量
 * @param parameter
 * @return {*}
 */
export function getUserOrderStatus (parameter) {
  return request({
    url: '/supplyportal/supplyorderinfo/getOrderCount',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取用户订单列表
 * @param parameter
 * @return {*}
 */
export function getUserOrderPageList (parameter) {
  return request({
    url: '/supplyportal/supplyorderinfo/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取商城用户代发订单列表
 * @param parameter
 * @return {*}
 */
export function getShipPageOrderList (parameter) {
  return request({
    url: '/supplyportal/supplyorderinfo/shipPageOrder',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取商城用户代发订单列表--- 一键代发
 * @param data
 * @return {*}
 */
export function getShipCreateOrder (data) {
  return request({
    url: '/supplyportal/supplyorderinfo/shipCreateOrder',
    method: 'post',
    data
  })
}

/**
 * 获取商城用户代发订单列表--- 取消
 * @param parameter
 * @return {*}
 */
export function getCancelShipOrder (parameter) {
  return request({
    url: '/supplyportal/supplyorderinfo/cancelShipOrder',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取订单详情
 * @param id
 * @return {*}
 */
export function getUserOrderObj (id) {
  return request({
    url: '/supplyportal/supplyorderinfo/' + id,
    method: 'get',
  })
}

/**
 * 获取批量下单的订单状态
 * @param id {string | number}
 * @return {*}
 */
export function getOrdersStatus(id) {
  return request({
    url: '/supplyportal/supplyorderinfo/queryPayOrderPayStatus/' + id,
    method: 'get'
  })
}

/**
 * 获取下单的订单状态
 * @param id {string | number}
 * @return {*}
 */
export function getOrderBooleanStatus(id) {
  return request({
    url: '/supplyportal/supplyorderinfo/queryOrderPayStatus/' + id,
    method: 'get'
  })
}

/**
 * 下单
 * @param data
 * @return {*}
 */
export function addOrder(data) {
  return request({
    url: '/supplyportal/supplyorderinfo',
    method: 'post',
    data
  })
}

/**
 * 批量付款接口
 * @param data {{orderIds: Array, payType: string}}
 * @return {*}
 */
export function payOrders(data) {
  return request({
    url: '/supplyportal/orderPay/create',
    method: 'post',
    data
  })
}

/**
 * 修改订单
 * @param data
 * @return {*}
 */
export function updateOrder(data) {
  return request({
    url: '/supplyportal/supplyorderlogistics/update',
    method: 'post',
    data
  })
}

/**
 * 统一下单接口
 * @param data {{payType: string, orderId: string, orderSn: string}}
 * @description 支付类型（wx-微信  ali-支付宝）
 * @return {*}
 */
export function unifiedOrder(data) {
  return request({
    url: '/supplyportal/supplyorderinfo/unifiedOrder',
    method: 'post',
    data
  })
}

/**
 * 根据订单ID 查询物流数据
 * @param orderId {string | Number}
 * @return {*}
 */
export function queryLogistics(orderId) {
  return request({
    url: '/supplyportal/supplyorderinfo/queryLogistics',
    method: 'get',
    params: { orderId }
  })
}

/**
 * 申请退款
 * @param data {{refundReson: string, orderId: string}}
 * @return {*}
 */
export function refundOrder(data) {
  return request({
    url: '/supplyportal/supplyorderinfo/applyRefund',
    method: 'post',
    data
  })
}

/**
 * 取消订单
 */
export function cancleOrder(data) {
  return request({
    url: '/supplyportal/supplyorderinfo/cancelOrder',
    method: 'post',
    data
  })
}

// 获取订单数量
export function getOrdersNum() {
  return request({
    url: '/supplyportal/supplyorderinfo/queryOrderStaticsCount',
    method: 'get',
  })
}

// 取消售后
export function revocationOrder(id) {
  return request({
    url: '/supplyportal/supplyorderinfo/cancelRefund?orderId=' + id,
    method: 'get',
  })
}

export function prefectRefundLogitics(data) {
  return request({
    url: '/supplyportal/supplyorderrefunds/prefectRefundLogitics',
    method: 'post',
    data:data
  })
}

// 确认收货
export function ConfirmGoods(orderId) {
  return request({
    url: '/supplyportal/supplyorderinfo/confirmTake',
    method: 'get',
    params: { orderId: orderId }
  })
}

// 获取个人中心价格
export function getPriceTotal(){
  return request({
    url:'/supplyportal/supplyorderinfo/queryOrderPrice',
    method:'get'
  })
}

// 订单统计
export function orderDeal(data){
  return request({
    url:'/supplyportal/supplyorderinfo/queryOrderStatistics',
    method:'post',
    data
  })
}

// 余额支付发送验证码
export function yuePay(){
  return request({
    url:'/supplyportal/supplyorderinfo/send/yuePay',
    method:'get'
  })
}

// 个人中心（我的关注）
export function getProductCount(){
  return request({
    url:'/supplyportal/userpurchase/getProductCount',
    method:'get'
  })
}
