/**
 * @author  XuHongli
 * @date  2022/7/7 15:44
 * @version 1.0
 * @description 英文语言包
 */
import antdEnUS from 'ant-design-vue/es/locale-provider/en_US'
import momentEU from 'moment/locale/eu'

const components = {
  antLocale: antdEnUS,
  momentName: 'eu',
  momentLocale: momentEU,
  index: 'index',
}

export default {
  ...components
}
