/**
 * @author  XuHongli
 * @date  2022/9/22 20:52
 * @version 1.0
 * @description Ant 全局配置
 */
import defaultSettings from '@/config/defaultSettings'
import { message, notification } from 'ant-design-vue'

/**  Ant MessageConfig */
message.config({
  duration: defaultSettings.antMessageDuration,
  maxCount: defaultSettings.antMessageMaxCount,
  top: defaultSettings.antMessageTop,
})

notification.config({
  duration: defaultSettings.antNotificationDuration,
})
