<template>
  <div class="head-viewed ">
    <a-dropdown v-model="showViewed">
      <svg aria-hidden="true" class="icon">
        <use xlink:href="#icon-viewed"></use>
      </svg>
      <div slot="overlay" class="dropdown-div" style="width: 330px;margin-left: 15px;">
        <h6><strong>浏览足迹</strong></h6>
        <template v-if="Object.keys(userInfo).length > 0 && infoProfect === 1">
          <a-spin :spinning="loading" tip="Loading...">
            <a-list :data-source="viewedList" class="IconViewedList" item-layout="horizontal">
              <a-list-item :key="item.id" slot="renderItem" slot-scope="item" style="justify-content: unset">
                <img :src="item.supplyProduct.imgUrl" width="70" />
                <div class="flex-column" style="font-size: 16px;height: 70px;margin-left: 20px">
                  <ellipsis :length="30" style="font-weight: 600;font-size: 20px">
                    {{ item.supplyProduct.name }}
                  </ellipsis>
                  <ellipsis :length="30">
                    {{ '￥' + item.supplyProduct.tradePrice }}
                  </ellipsis>
                </div>
              </a-list-item>
            </a-list>
          </a-spin>
          <a-button style="width: 100%;" type="primary" @click="$router.push('/user/viewed')">查看更多</a-button>
        </template>
        <template v-else>
          <a-button style="width: 100%;" type="primary"
            @click="$router.push($tp().IS_OPEN == 1 ? 'user/login' : '/login')">
            {{ Object.keys(userInfo).length === 0 ? '登录后查看更多' : infoProfect === 0 ? '信息未完善' : '' }}
          </a-button>
        </template>
      </div>
    </a-dropdown>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/25 18:24
 * @version 1.0
 * @description
 */
import { getUserViewed } from '@/api/Viewed'
import { mapGetters } from 'vuex'
import Ellipsis from '@/components/Ellipsis/Ellipsis'

export default {
  name: 'IconViewed',
  components: { Ellipsis },
  data() {
    return {
      loading: true,
      showViewed: false,
      viewedList: [],
      queryParam: {
        current: 1,
        size: 15,
        type: 1
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect'])
  },
  watch: {
    showViewed(newValue, oldValue) {
      if (newValue === true && oldValue === false && Object.keys(this.userInfo).length > 0) {
        this.get()
      }
    }
  },
  created() {
    this.get()
  },
  methods: {
    get() {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        getUserViewed(this.queryParam).then((res) => {
          this.loading = false
          this.viewedList = res.data.records
        })
      } else {
        this.loading = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.head-viewed {
  cursor: pointer;

  .icon {
    font-size: $iconFontSize;
  }
}

.dropdown-div {
  padding: 20px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.IconViewedList {
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
