import request from '@/utils/axios'
// 充值记录列表查询
export function Rechargeage (data) {
  return request({
    url: '/supplyportal/supplyWalletRecharge/page',
    method: 'get',
    params:data
  })
}

// 提现记录列表查询
export function supplyWalletWithdrawalsPage (data) {
  return request({
    url: '/supplyportal/supplyWalletWithdrawals/page',
    method: 'get',
    params:data
  })
}

export function GetMenu() {
  return request({
    url: '/upms/menu',
    method: 'get'
  })
}

//
// 子账号管理


export function GetSuber() {
  return request({
    url: '/upms/subuser/page',
    method: 'get'
  })
}


export function getMenuTree(query) {
  return request({
    url: '/upms/menu',
    method: 'get',
    params: query
  })
}

export function addAccount(data) {
  return request({
    url: '/upms/subuser/add',
    method: 'post',
    data
  })
}

export function delAccount(id) {
  return request({
    url: '/upms/subuser/' + id,
    method: 'DELETE',
  })
}

export function editSubUserPassword(obj) {
  return request({
    url: '/upms/subuser/password',
    method: 'put',
    data: obj
  })
}

export function fetchSubUserTree(id) {
  return request({
    url: '/upms/subuser/tree/' + id,
    method: 'get'
  })
}

export function permissionUserUpdate(data) {
  return request({
    url: '/upms/subuser/updateSubUserMenu',
    method: 'post',
    data
  })
}
