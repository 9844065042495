<template>
  <div style="width:1200px;">
    <p>{{  }}</p>
    <div class="tab">
      <div v-for="(item,index) in tab" :key="item.id" class="tabitem" @click="cut(item,index)">
        <span :class="isIndex==item.id?'tabSpan':''">{{ item.title }}</span>
        <div :style="isIndex==item.id?'color: red;':''">{{ item.name }}</div>
      </div>
    </div>
    <div :class="csdn?'csbg':''">
      <listCard :list="cutList" :size="12" @handleAddGoods="handleAddGoods" ></listCard>
    </div>
  </div>
</template>

<script>
import listCard from '@/components/listCard/card'

 export default {
  components:{listCard},
  props:{
    cutList:{
      type:[Array,String],
      default:() => []
    }
  },
  data() {
    return {
      tab:[
        {id:0,title:'新品推荐',name:'好物尝鲜'},
        {id:1,title:'品牌精选',name:'理想生活'},
        {id:2,title:'每周必抢',name:'惊喜放价'},
        {id:3,title:'人气热卖',name:'大牌钜惠'},
        {id:4,title:'网红爆品',name:'激情热购'},
        {id:5,title:'本月特惠',name:'"惠"等你来'},
      ],
      isIndex:0,
      emitIndex: 0,
      csdn:false
    }
  },
  methods:{
    handleAddGoods() {
      this.$emit('handleAddGoodsgs', this.emitIndex)
    },
    cut(item,index){
      this.isIndex = item.id
      this.emitIndex = index
      this.csdn = true
      setTimeout(() => {
        this.csdn = false
      },300)
      this.$emit('cut',index)
      // getRandomProduct().then(res => {
      //   console.log('asddada',res)
      // })
    },
  }
}
</script>

<style lang="scss" scoped>

.csbg{
  width: 100%;
  height: auto;
  background: rgb(223, 221, 221);
  opacity: 0.3;
  // position: fixed;
  z-index: 100;
}
.tabSpan{
  background-color: red;
  color: rgb(255, 255, 255);
}
.tabDIV{
  color: red;
}
.tab{
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
.tabitem{
  width: 16.66%;
  background-color: #fff;
  line-height: 20px;
  padding: 13px 0;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid;
  color: black;
  -o-border-image: linear-gradient(180deg,#fff,red,#fff) 1 1;
  border-image: linear-gradient(180deg,#fff,red,#fff) 1 1;
  span{
    display: inline-block; padding: 4px 20px; border-radius: 30px;
  }
}
.tabitem div:last-child{
  font-size: 12px;
  margin-top: 4px;
  color: rgb(153, 153, 153);
}
.tabitem:last-child {
    border-right: 0;
}
</style>
