import request from '@/utils/axios'

/** 获取分页获取京东产品列表 */
export function jdgoodsList(params) {
  return request({
    url: '/supplyportal/jdgoods/page',
    method: 'GET',
    params
  })
}

/** 树状分类 */
export function jdgoodsTree(params){
  return request({
    url: '/supplyportal/jdgoods/tree',
    method: 'get',
    params
  })
}

/** 入库 */
export function jdgoodsPutin(data){
  return request({
    url: `/supplyportal/jdgoods/putin`,
    method: 'post',
    data
  })
}

/** 详情 */
export function selfQueryProduct(id){
  return request({
    url: `/supplyportal/jdgoods/getDetails/${id}`,
    method: 'GET',
  })
}
