/**
 * @author  XuHongli
 * @date  2022/9/5 11:13
 * @version 1.0
 * @description 用户足迹
 */

import request from '@/utils/axios'

export function getUserViewed(parameter) {
  return request({
    url: '/supplyportal/supplyproductfoot/page',
    method: 'get',
    params: parameter
  })
}
