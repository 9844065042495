<template>
  <div class="help-layout" id="help-layout" :class="[layoutClass]">
    <TopWarp></TopWarp>
    <HeadWarp></HeadWarp>
    <div class="main-content mb-20 flex-row flex-row-between">
      <HelpMenu></HelpMenu>
      <div class="right-content">
        <router-view></router-view>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/Layouts/components/Footer'
import TopWarp from '@/Layouts/components/TopWarp'
import HeadWarp from '@/Layouts/Default/Header/HeadWarp'
import HelpMenu from '@/Layouts/components/HelpMenu'
/**
 * @author  XuHongli
 * @date  2022/11/15 9:04
 * @version 1.0
 * @description
 */
export default {
  name: 'HelpLayout',
  components: { HelpMenu, HeadWarp, TopWarp, Footer },

  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass() {
      return this.$route.meta.layoutClass
    }
  }
}
</script>

<style lang="scss">
.help-layout {
  .right-content {
    flex: 1;
    margin-left: 20px;
    min-height: 836px;
    border: 1px solid #dbdbdb;
    padding: 40px 30px;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;

    .title {
      font-size: 32px;
      color: #333;
      text-align: center;
      font-weight: 700;
    }

    p {
      color: #333;
      margin: 14px 0;
    }
  }
}
</style>
