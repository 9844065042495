<template>
  <div>
    <a-row>
      <a-col v-for="(item,index) in state" :key="index" :span="6" class="col">
        <div :href="'/search?searchName='+item.id+'$ist='" @click="goc(item,index)">
          <img v-if="index<8" :src="item.img" class="natimg"></img>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import router from '@/router/routers'
import {  allLocality } from '@/api/Product'

export default {
  data() {
    return {
      state:[],
    }
  },
  created(){
    allLocality().then(res => {
      let list = []
      for(var i=0;i<res.data.length;i++){
          if(res.data[i].country == '英国'
            || res.data[i].country == '韩国'
            || res.data[i].country == '美国'
            || res.data[i].country == '日本'
            || res.data[i].country == '法国'
            || res.data[i].country == '澳洲'
            || res.data[i].country == '德国'
            || res.data[i].country == '荷兰'){
              list.push({
                title:res.data[i].country,
                id:res.data[i].id,
                img:'/img/'+(i-1)+'.jpg'
              })
           }
      }
      this.state = list
    }).catch(err => {
      console.error(err)
    })
  },
  methods:{
    goc(item,index){
      index
      let ist = this.guan(item.title)
      this.$router.push({path:'/search?countyIds='+item.id,query:{ist:item.id}})
    },
    guan(val){
      let tp = ''
      switch(val){
        case '中国':
        tp = 1
        break
        case '瑞士':
        tp = 2
        break
        case '英国':
        tp = 3
        break
        case '韩国':
        tp = 4
        break
        case '美国':
        tp = 5
        break
        case '日本':
        tp = 6
        break
        case '法国':
        tp = 7
        break
        case '澳洲':
        tp = 8
        break
        case '德国':
        tp = 9
        break
        case '荷兰':
        tp = 10
        break
      }
      return tp
    },
  },
}
</script>

<style scoped>
  .natimg{
    width: 95%;
    cursor: pointer;
  }
  .col{
    margin-top: 15px;
    text-align: center;
  }
</style>
