import request from '@/utils/axios'

/** 获取商品列表*/
export function selfQueryProductList(data) {
  return request({
    url: '/supplyportal/self/queryProductList',
    method: 'post',
    data
  })
}

/** 供应商商品分类查询*/
export function selfQueryCate(params){
  return request({
    url: '/supplyportal/self/queryCate',
    method: 'get',
    params
  })
}

/** 入库 */
export function selfPutin(data){
  return request({
    url: `/supplyportal/self/putin`,
    method: 'post',
    data
  })
}

/** 详情 */
export function selfQueryProduct(params){
  return request({
    url: `/supplyportal/self/queryProduct`,
    method: 'get',
    params
  })
}
