/**
 * @author  XuHongli
 * @date  2022/2/18 15:08
 * @version 1.0
 * @description antModal Mixins
 */
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    }
  },
  watch: {
    /**
     * 监听showModal的变化
     * @param newVal
     */
    value: function (newVal) {
      this.visible = newVal
    }
  },
  data () {
    return {
      visible: this.value
    }
  },
  methods: {
    /**
     * 关闭Modal
     */
    closeModal () {
      return this.$emit('input', false)
    }
  },
}
