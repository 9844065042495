/*
*   专区管理
*/

import request from '@/utils/axios'

/* 首页专区列表 */
export function getPrecinctList () {
  return request({
    url: '/supplyportal/supplySpecial/list',
    method: 'get'
  })
}

/* 通过专区id获取商品列表 */
export function getSpecial (query) {
  return request({
    url: '/supplyportal/supplySpecial/getSpecial',
    method: 'get',
    params: query
  })
}
