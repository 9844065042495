/**
 * @author  XuHongli
 * @date  2022/7/11 11:16
 * @version 1.0
 * @description 项目默认配置项
 */

/**
 * title        ->  网站标题
 * iconfontUrl  ->  iconfont 地址
 * timeout      ->  连接超时时间
 * token_expires->  token本地过期时间
 * antMessageDuration -> Ant Message（页面上方中间） 全局提示持续时间
 * antNotificationDuration -> Ant Notification (页面右上角) 全局提示持续时间
 * antMessageMaxCount -> Ant Message 最大显示数, 超过限制时，最早的消息会被自动关闭
 * antNotificationMaxCount -> AntNotification 全局信息最大显示数, 超过限制时，最早的消息会被自动关闭 (3.0+) 暂时不生效!!
 * antMessageTop      -> Ant Message 到页面顶部距离
 */

export default {
  title: '供应链',
  iconfontUrl: '',
  timeout: 30000,
  token_expires: 0.4,
  antMessageDuration: 2,
  antNotificationDuration: 2,
  antMessageMaxCount: 1,
  antNotificationMaxCount: 1,
  antMessageTop: '20px',
}
