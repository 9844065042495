
import router, { NotFoundRouters, routes } from './routers'
import store from '@/store'
import NProgress from 'nprogress'
import { UserRouters } from './userRouters'
import Vue from 'vue'
import { helpRouters } from '@/router/helpRouters'
import { mapState } from 'vuex'

NProgress.configure({ showSpinner: false })// NProgress Configuration

/**
 * 路由白名单
 * @type {(string|*)[]}
 * @example [...routers,...].map
 */
const whiteList = [...routes, ...NotFoundRouters, ...helpRouters].map((item) => {
  return item.path
}) // 路由白名单

const userRouteList = UserRouters.map((item) => {
  return item.path
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  NProgress.start() // start progress bar
  if (store.getters.access_token) {
    if (userRouteList.indexOf(to.path) >= 0) {
      store.dispatch('GetUserInfo').then((res) => {
        if (res.sysUser.infoProfect === 0 || res.sysUser.infoProfect === 2) {
          Vue.prototype.$confirm(res.sysUser.infoProfect === 0 ? '你的信息还未完善，是否确认完善信息' : '待后台人员审核', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success'
          }).then(() => {
            if (res.sysUser.infoProfect === 0) {
              next({path: '/registerSteps?id='+res.sysUser.infoProfect})
            }
            if (res.sysUser.infoProfect === 2) {
              next({path: '/registerSteps?id='+res.sysUser.infoProfect})
            }
            if(res.sysUser.infoProfect === 1) next({path: from.path})
          })
          next({path: from.path})
        } else {
          next()
        }
      })
    }
    // 如果已登录是厂家直供页面
    if (to.path === '/user/supplyFromManufacturer') {
      // 开通了 厂家直供
      if (store.state.user.isEnableCh === 1){
        next()
      } else {
        next({path: '/user/userCenter'})
      }
    }

    // 如果已登录切跳转的页面是登录页
    if (to.path === '/login') {
      next({path: '/'})
      NProgress.done()
    } else {
      // 初始没有用户信息，重新获取用户信息
      if (Object.keys(store.getters.userInfo).length === 0) {
        await store.dispatch('GetUserInfo').then(() => {
          next({...to, replace: true})
        }).catch(() => {
          store.dispatch('FedLogOut').then(() => {
            next({path: '/'})
          })
        })
      }
      next()
    }
  } else {
    /* has no token */
    if (whiteList.indexOf(to.path) !== -1 || to.path.indexOf('/commodity/') !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      next('/')
      // let webInfo = JSON.parse(localStorage.getItem('Web_key_word'))
      // let IS_OPEN = ''
      //  webInfo.forEach(item => {
      //    if (item.name = 'IS_OPEN') {
      //      IS_OPEN = item.value
      //    }
      //  })
      // if (IS_OPEN == 1) {
      //   let PAGE_URL = window.origin + '/user/login'
      //   window.open(PAGE_URL, '_blank')
      // } else {
      //   next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
      // }

      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
