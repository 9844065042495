/**
 * @author  XuHongli
 * @date  2022/11/14 14:05
 * @version 1.0
 * @description 页脚数据配置项
 */

export const footerOptions = [
  {
    title: '公司特色',
    item: [{
      name: '公司简介',
      link: '/support/company'
    },{
      name: '无忧代发',
      link: '/support/dropShipping'
    },{
      name: 'SaaS服务',
      link: '/support/SaaS'
    }]
  },
  {
    title: '新手入门',
    item: [{
      name: '常见问题',
      link: '/support/FAQ'
    },{
      name: '优惠券规则',
      link: '/support/ruleOfCoupons'
    }]
  },
  {
    title: '正品保障',
    item: [{
      name: '种类齐全',
      link: '/support/fullRange'
    },{
      name: '购买须知',
      link: '/support/purchaseNotes'
    }]
  },
  {
    title: '售后保障',
    item: [{
      name: '配送服务',
      link: '/support/deliveryServices'
    },{
      name: '退货流程',
      link: '/support/returnsProcess'
    },{
      name: '退货政策',
      link: '/support/returnsPolicy'
    }]
  },
  {
    title: '商家合作',
    item: [{
      name: '合作说明',
      link: '/support/descriptionOfCooperation'
    },{
      name: '合作建议及反馈',
      link: '/support/suggestionsFeedback'
    },{
      name: 'saas商城软件说明',
      link: '/support/softwareDescriptionOfSaaSMall'
    }]
  },
  {
    title: '关于我们',
    item: [{
      name: '商品税率表',
      link: '/support/POR'
    },{
      name: '联系我们',
      link: '/support/contactUs'
    }]
  },
  {
    title: '友情链接',
    item: [{
      name: '国家药品监督管理局',
      link: 'https://www.nmpa.gov.cn/'
    },{
      name: '中国海关总署',
      link: 'http://www.customs.gov.cn/'
    }]
  }
]
