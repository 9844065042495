<template>
  <a-modal
    okText="确认"
    cancelText="取消"
    :visible="visible"
    width="600px"
    centered
    title="确认删除"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <div class="deleteMsg">
      <p>确认删除选中的（{{ ids.length }}个）商品，商品将在我的收藏中不可见。</p>
      <p>删除操作不可逆</p>
    </div>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { deleteSelectGoodsAll } from '@/api/SelectGoods'

/**
 * @author  XuHongli
 * @date  2022/12/2 16:23
 * @version 1.0
 * @description 删除收藏商品 弹窗
 */
export default {
  name: 'DeleteFavoriteGoodsModal',
  mixins: [ ShowModalMixin ],
  props: {
    ids: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleOk() {
      let data = {
        deleteAll: 0,
        productIds: this.ids
      }
      deleteSelectGoodsAll(data).then(() => {
        this.$notification.success({
          message: `删除成功`,
          description:
            `您已成功移除产品.`,
        })
        this.closeModal()
        this.$emit('ok',true)
      })
    }
  }
}
</script>

<style scoped>

</style>
