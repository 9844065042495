<template>
  <div class="top-Warp">
    <div id="top-Info" class="top-Info" style="width: 1200px;">
      <div class="top-Left">
        <ul id="nav-l-en" style="padding: 0;margin: 0;">
          <li class="t-Merchant">
            <span v-show="infoProfect !== 0">您好，</span>
            {{ Object.keys(userData).length ? userData.businessName : webKeyword.OPEN_SYSTEM_NAME + '欢迎您！' }}
          </li>
          <li class="t-Divider" style="margin-left:20px;"></li>
          <li class="t-Merchant" style="margin-left:20px;">
            <label v-if="userInfo.username && userInfo.infoProfect != 3" class="cus" @click="logout()"> 退出 </label>
            <label v-else style="cursor:pointer;" @click="onJumpLogin()">登录</label>
            <!--            <a href="/login"  >登录</a>-->
          </li>
        </ul>
      </div>
      <div class="top-Right">
        <ul id="nav-l-en" style="padding: 0;margin: 0;">

          <li v-if="Object.keys(userInfo).length != 0" class="t-Protection">
            <router-link to="/user/order?name=我的订单">我的订单</router-link>
            <!--            <a href="/user/order" > 我的订单 </a>-->
          </li>
          <li v-if="Object.keys(userInfo).length != 0" class="t-Divider"></li>
          <li id="t-Help" class="t-Help">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="$router.push({ path: '/user/userCenter' })">
                个人中心 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-if="$tp().IS_OPEN != 1">
                  <router-link to="/user/userCenter">我的账户</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link to="/user/shopLibrary?name=选品库">我的选品库</router-link>
                </a-menu-item>
                <a-menu-item v-if="$tp().IS_OPEN != 1">
                  <router-link to="/user/authentication">账户管理</router-link>
                </a-menu-item>
                <a-menu-item v-if="$tp().IS_OPEN != 1">
                  <router-link to="/user/security">账户安全</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link to="/user/favorite?name=我的收藏">我的收藏</router-link>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </li>
          <li class="t-Divider"></li>
          <li id="t-Help" class="t-Help">
            <a-dropdown>
              <router-link to="/user/address?name=收货地址">我的地址</router-link>
            </a-dropdown>
          </li>
          <li class="t-Divider"></li>
          <li class="t-Protection">
            <span style="cursor: pointer;color: #df3028" @click="ssss"> 品牌方入驻 </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import SelectLang from '@/components/SelectLang'
import { mapGetters } from 'vuex'
import { getUserInfoRz } from '@/api/Login'


export default {
  name: 'TopWarp',
  components: { SelectLang },
  data() {
    return {
      showShipTo: false, // 是否显示 *运送到*下拉框
      currency: 'RMB', // 选择货币
      shipTo: 'China', // 运送到 国家
      login: '',
      userData: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect', 'webKeyword'])
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      // 转为宏任务对象
      setTimeout(() => {
        if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
          getUserInfoRz().then(res => {
            this.userData = res.data
          })
        }
      }, 120)
    })
  },

  methods: {
    // 登录
    onJumpLogin() {
      let PAGE_URL = ''
      if (process.env.NODE_ENV === 'pre') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
      } else if (process.env.NODE_ENV === 'production') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'https://console.wanjingai.com/index'  // 退出回到商务中心index页面
      } else {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
      }
      if (this.$tp().IS_OPEN == 1) window.open(PAGE_URL, '_blank')
    },
    URP() {
      if (this.$tp().IS_OPEN == 1) {
        let tps = window.origin + '/user/login'
        window.open(tps, '_blank')
      } else {
        this.$router.push({ path: '/login' })
      }
    },
    logout() {
      let PAGE_URL = ''
      let that = this
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        localStorage.removeItem('historyList');

        if (0 == that.$tp().IS_OPEN) {
          that.$store.dispatch('FedLogOut').then(() => {
            that.$router.push('/login')
          })
        } else if (1 == that.$tp().IS_OPEN) {
          if (process.env.NODE_ENV === 'pre') {
            PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
          } else if (process.env.NODE_ENV === 'production') {
            PAGE_URL = 'https://console.wanjingai.com/index'  // 退出回到商务中心index页面
          } else {
            PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
          }
          window.location.replace(PAGE_URL)
        }

      })
    },
    ssss() {
      // let PAGE_URL = ''
      // if (process.env.NODE_ENV === 'pre') {
      //   PAGE_URL = 'http://smpre.aijdan.com/#/rescter'
      // } else if (process.env.NODE_ENV === 'production') {
      //   PAGE_URL = 'http://sm.aijdan.com/#/rescter'
      // } else {
      //   PAGE_URL = 'http://smpre.aijdan.com/#/rescter'
      // }
      // if (this.webKeyword.OPEN_SM_RESCTER) PAGE_URL = this.webKeyword.OPEN_SM_RESCTER
      // window.open(PAGE_URL, '_blank')
      console.log('OPEN_DOMAIN', this.$tp().OPEN_DOMAIN) // OPEN_DOMAIN --------线上域名
      console.log('OPEN_SSM_RESCTER', this.$tp().OPEN_SSM_RESCTER)  // OPEN_SSM_RESCTER--------本地网址
      let manufacturers = this.$tp().OPEN_SSM_RESCTER ? `${this.$tp().OPEN_SSM_RESCTER}?type=Manufacturer` : `http://${this.$tp().OPEN_DOMAIN}/registerSteps?type=Manufacturer`
      window.open(manufacturers)
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-shipto {
  background-color: #fff;
  padding: 15px;
  width: 230px !important;
  //height: 192px !important;
  border: solid 1px #e4e4e4;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.top-Warp {
  height: 31px;
  color: #999;
  background-color: #e3e4e5;
  border-bottom: solid 1px #e9e9e9;
  z-index: 10;

  a {
    color: #999;
  }
}

.top-Left {
  padding-right: 2px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;

  ul {
    float: right;
    display: block;
    clear: both;
  }

  li {
    float: left;
    line-height: 30px;
    position: relative;
  }

  li.t-Divider {
    margin: 10px 0;
    height: 10px;
    width: 1px;
    background-color: #999;
  }
}

.top-Right {
  padding-right: 2px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;

  ul {
    float: right;
    display: block;
    clear: both;
  }

  li {
    float: left;
    line-height: 30px;
    position: relative;
  }

  li.t-Divider {
    margin: 10px 0;
    height: 10px;
    width: 1px;
    background-color: #999;
  }
}

.saveBtn {
  width: 100%;
  background-color: $color-orange;
  color: #fff;
  margin-top: 10px;
  font-size: 16px;

  &:hover {
    border-color: #801602;
  }
}

.cus {
  cursor: pointer;
}
</style>
