/**
 * @author  XuHongli
 * @date  2022/12/1 17:29
 * @version 1.0
 * @description 全局动画触发 Vuex
 */

const animation = {
  state: {
    // 收藏之后FavoritePanel 触发的 "+1" 动画
    triggerFavoriteAnimation: false,
    // 触发动画
    updateFavoriteListCount: 0,
  },
  mutations: {
    SET_TRIGGER_FAVORITE_ANIMATION: (state, value) => {
      state.triggerFavoriteAnimation = value
    },
    SET_UPDATE_FAVORITE_LIST_COUNT: (state) => {
      state.updateFavoriteListCount ++
    }
  }
}

export default animation
