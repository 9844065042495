<template>
  <div v-if="$store.state.bottom" class="footer-box pb-20">
    <div v-show="hasAdImage" v-if="$store.state.bottom" class="fimg"></div>
    <div class="foot-website" style="width: 1200px">
      <a-row class="pt-50">
        <a-col v-for="(Option, index) in footerOptions" :key="index" :md="3" :sm="3" :span="3" :xs="3"
          style="height: 150px">
          <p class="OptionTitle">{{ Option.title }}</p>
          <ul class="Options">
            <li v-for="(item, number) in Option.item" :key="`${index}-${number}`">
              <router-link v-if="item.link.indexOf('http') === -1" :to="item.link" target="_blank">{{ item.name
              }}</router-link>
              <a v-else :href="item.link" target="_blank">{{ item.name }}</a>
            </li>
          </ul>
        </a-col>
        <a-col :span="3">
          <img :src="webKeyword.OPEN_QR_CODE" alt="" style="width: 60%;height: auto">
        </a-col>
      </a-row>
    </div>
    <Copyright></Copyright>
  </div>
</template>

<script>
/**
 * @author: XuHongli
 * @description: 页脚组件
 */
import { footerOptions } from '@/Layouts/Help/FooterOptions'
import Copyright from '@/Layouts/components/Footer/Copyright'
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  components: { Copyright },
  computed: {
    ...mapGetters(['webKeyword'])
  },
  props: {
    hasAdImage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      footerOptions,
      bottom: ''
    }
  },
}
</script>

<style lang='scss' scoped>
.fimg {
  width: 100%;
  height: 120px;
  background-size: cover;
  background-position-y: 53%;
  background-image: url("https://file.jingpingo.com/supply/material/83971ec2-f1e2-42bd-8379-9f0722bc2604.png");
}

.footer-box {
  color: #666;
  background-color: #f4f4f4;
}

.OptionTitle {
  font-size: 18px !important;
  margin-bottom: 10px;
  color: #666;
}

ul.Options {

  a {
    color: #666;

    &:hover {
      text-decoration: underline;
    }
  }

  color: #666;
  padding-left: 0;
}
</style>
