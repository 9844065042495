<template>
  <div class="layout-user" id="layout-user" :class="[layoutClass]"
    :style="{ background: `url(${adBanner}) no-repeat center top` }">
    <TopWarp></TopWarp>
    <UserHeadWarp></UserHeadWarp>
    <div class="userCenter">
      <!--  Nav导航条  -->
      <!-- <DefaultNavigation :menu="userNavOptions"/> -->
      <div class="main-content" style="padding-bottom: 35px">
        <div class="content ">
          <!--    左侧部分    -->
          <div class="left-content">
            <!--     快捷     -->
            <Shortcuts></Shortcuts>
            <!--     用户信息通知     -->
            <!-- <UserCenterMessage></UserCenterMessage> -->
          </div>
          <!--    右侧侧部分    -->
          <div class="right-content mt-10">
            <router-view style="padding-bottom: 20px" />
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>

  </div>
</template>

<script>
import Shortcuts from '@/components/Shortcuts'
import TopWarp from '@/Layouts/components/TopWarp'
import HeadWarp from '@/Layouts/Default/Header/HeadWarp'
import Footer from '@/Layouts/components/Footer'
import UserHeadWarp from '@/Layouts/Default/Header/UserHeadWarp.vue'
/**
 * @author  XuHongli
 * @date  2022/11/29 14:40
 * @version 1.0
 * @description 用户中心布局
 */
export default {
  name: 'UserLayout',
  components: { UserHeadWarp, Footer, HeadWarp, TopWarp, Shortcuts },
  data() {
    return {
      adBanner: ''
    }
  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass() {
      return this.$route.meta.layoutClass
    }
  }
}
</script>

<style scoped>
.layout-default {
  min-height: 100%;
}

.drainage-banner a {
  display: block;
  width: 100%;
  height: 60px;
}
</style>
<style scoped lang="scss">
.userCenter {
  min-height: 100%;
}

.content {
  display: flex;

  .right-content {
    margin-left: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 700px;
  }
}
</style>

