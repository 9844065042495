/**
 * @author  XuHongli
 * @date  2022/7/11 11:33
 * @version 1.0
 * @description
 */

export const ACCESS_TOKEN = 'Access-Token'

export const TOGGLE_MOBILE_TYPE = 'is_mobile'
export const APP_LANGUAGE = 'app_language'
