<template>
  <div class="head-shopCart ">
    <a-dropdown v-model="showShopCart">
      <div class="joinText ant-dropdown-link flex-row flex-col-center">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-shopCart"></use>
        </svg>
        <div class="shopCartText">
          <p style="margin: 0">
            Cart <a-icon type="down" />
          </p>
        </div>
      </div>
      <div class="dropdown-div" style="width: 230px" slot="overlay">
        <h6><strong>ShopCart</strong></h6>
      </div>
    </a-dropdown>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/25 18:26
 * @version 1.0
 * @description
 */
export default {
  name: 'IconShopCart',
  data() {
    return {
      showShopCart: false
    }
  },
}
</script>

<style scoped lang="scss">
.dropdown-div{
  padding: 20px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
}
.head-shopCart{
  .icon {
    font-size: $iconFontSize;
  }
}
</style>
