<template>
  <div class="top-left-top" :style="{ width: typeof width === 'number' ? width + 'px' : width }">
    <!-- 以下为旧数据 -->
    <!-- <h3 style="font-weight: 700;font-size: 16px">{{ title }}</h3> -->
    <!-- <ul class="select-ul">
      <li v-for="(item, index) in options" :key="index">
        <router-link :to="item.url">{{ item.titleName }}</router-link>
      </li>
    </ul> -->
    <div class="select-ul">
      <div v-for="(item, index) in options" :key="index" class="list">
        <img :src="item.icon" alt="">
        <router-link :to="item.url">{{ item.titleName }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/29 11:56
 * @version 1.0
 * @description
 */
export default {
  name: 'ShortcutsHome',
  props: {
    width: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: '260px'
    },
    title: {
      type: String,
      default: '捷径'
    },
    selectIndex: {
      type: Number,
      default: 0
    },
    home: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => {
        return [
          {
            titleName: '个人中心',
            url: '/user/userCenter',
            icon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/5a85b903-e000-4b87-ad73-cb6b738140bf.png',
            selectIcon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/cc580721-acd0-4072-b875-74551e4da8c4.png'
          },
          {
            titleName: '我的订单',
            url: '/user/order',
            icon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/8a279ae2-097b-438e-af7f-55cd1e2265da.png',
            selectIcon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/bee615de-0fe1-49c7-be9c-4ebe1aff8e56.png'
          },
          {
            titleName: '商品库',
            url: '/user/shopLibrary',
            icon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/d506dff7-f1ee-4d2d-bdc0-030a77d05775.png',
            selectIcon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/3fb7ddaa-b371-4cc5-9486-9b0559d5c1f2.png'
          },
          {
            titleName: '我的收藏',
            url: '/user/favorite',
            icon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/3259b87f-63c0-434a-a00c-19c80c667f44.png',
            selectIcon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/677c0840-0095-4def-be53-e9945f65b874.png'
          },
          {
            titleName: '历史记录',
            url: '/user/viewed',
            icon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/9d5d3233-2592-4d26-9a74-537e25435627.png',
            selectIcon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/d5869d70-dc39-4653-a26d-4f25704f768d.png'
          },
          {
            titleName: '我的地址',
            url: '/user/address',
            icon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/8688920c-c823-44e2-bfdd-72b5a6081a08.png',
            selectIcon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/dc44971a-e790-4d26-aa40-7bb8e8485db6.png'
          },
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
a {
  display: inline;
  width: 190px;
}

.top-left-top {
  width: 190px;
  background-color: #fff;
  // margin-bottom: 15px;
}

.select-ul {
  text-indent: 1rem;
  margin: 0;
  padding: 0;

  .list {
    width: 220px;
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border-radius: 4px;
    border-bottom: 1px solid #eee;
    padding: 0 0 0 10px;
    font-weight: 600;
    font-size: .7rem;

    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
    }
  }

  .list:hover {
    background-color: #e7e7e7;
  }

  .a {
    color: #fff;
  }

  .active {
    background: #0962ea;
    color: #fff;
  }
}
</style>
