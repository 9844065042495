<template>
  <div style="width:1200px;">
    <a-row>
      <a-col :span="24">
        <div class="top20 picDis">
          <div>
            <img class="imgpic" src="https://file.jingpingo.com/supply/material/aba209e5-5a7a-469f-9cfb-658ed8d122ec.png" @click="recommend('每日推荐')" />
          </div>
          <div class="picRight">
            <div v-for="(item,index) in picList.slice(0, 4)" :key="index" class="picRight-item" @click.stop="onPrefecture(item)">
              <div class="picDis title-box">
                <p style="cursor: pointer;">{{ item.name }}</p>
                <p style="cursor: default;font-size: 14px; padding-bottom: 8px; color: rgba(46, 51, 83, 0.75);">更多></p>
              </div>
              <div class="item-box picpad" >
<!--                <img v-for="(it,index) in item.imgList" @click.stop="$router.push({path:'/search?tradeType='+item.id})" :src="it" :key="index" />-->
                <img :src="item.centerImg" />
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

  </div>

</template>

<script>
export default {
  props:{
    picList:{
      type:Array,
      default:() => []
    }
  },
  data() {
    return {

    }
  },
  methods:{
    onPrefecture(val) {
      if (val.id) {
        this.$router.push({
          path:'/prefecture',
          query: {
            id: val.id
          }
        })
      }
    },
    //每日推荐
    recommend(val) {
      if(val === '每日推荐') return this.$router.push({path:''})
      this.$router.push({path:''})
    }
  }
}
</script>

<style lang="scss" scoped>
  .item-box{
    width: 100%;
    height: 130px;
    img{
      width: 100%;
      height: 100%;
      // transition: transform .5s;
      cursor: pointer;
      transition: all 0.6s;
    }

  }
  .title-box{
    width: 100%;
    height: 28%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .picpad{

  }
  .item-box img:hover {
    //  height: 105%;
     transform: scale(1.1);
  }
  .imgpic{
    width: 396px;
    height: 412px;
    margin-top: -11px;
  }
  .picDis {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .picRight{
    width: 70.5%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .picRight-item{
    width: 390px;
    height: 200px;
    margin-bottom:11px;
    padding:0 25px ;
    margin-left: 11px;
    background-color: #fff;
    p{
      font-size: 26px; margin-right: 10px; margin-left: 50px; color: rgb(46, 51, 83); padding-bottom: 4px;
    }

  }
</style>
