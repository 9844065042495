/**
 * @author  XuHongli
 * @date  2022/12/5 11:05
 * @version 1.0
 * @description 函数式组件 全局挂载文件
 */

import Vue from 'vue'
import { _AddGroupModal } from '@/components/FunctionComponents/AddGroupModal'

/**
 * 挂载到Vue实例
 * @param fn {function} 函数体
 * @param name {string} 函数名称
 * @return {function} 原函数
 */
export const withInstallFunction = (fn, name) => {
  fn.install = () => {
    Vue.prototype[name] = fn
  }
  fn.install()
  return fn
}

const FunctionComponentsList = {
  /**
   * @param props { {ids: Array<string>, handleOk: Function, isHistory?: Boolean} }
   * @description ids加入分组的数组, handleOk回调函数
   */
  $AddGroupModal : withInstallFunction(_AddGroupModal,'$AddGroupModal'),
}


export default FunctionComponentsList
