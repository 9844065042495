<template>
  <div class="HomeGoodsCategory" style="  background-color: #fff; position:relative;margin: 0 auto;"
    @mouseleave="hoverNavMenu = false">
    <!--   标签栏   -->
    <div class="xia" @mousemove.self="hoverNavMenu = false">
      <div class="topTabBar flex-row  main-content" style="padding-top: 0">
        <div v-for="(item, index) in threeData" :key="index"
          :style="{ color: active == item.type ? '#ff4400' : '#333' }" class="title-tou"
          @click="onSkip2(item.type, index, item)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/25 19:54
 * @version 1.0
 * @description 首页分类
 */
import { categoryTabList } from '@/views/Home/HomeGoodsCategory/categoryTabList'

export default {
  name: 'HomeGoodsCategory',
  props: {
    titleData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      threeData: [
        {
          type: 1,
          name: '精选商品'
        },
        {
          type: 3,
          name: '京东品质保证'
        },
        {
          type: 2,
          name: '1688厂货直供'
        },
      ],
      active: 1,
      hoverNavMenu: true,
      categoryTabList, // 分类标签栏数据
      bannerList: [],//轮播图
    }
  },
  watch: {
    $route(n, o) {
      if (n.query.type) {
        this.active = n.query.type
      } else {
        this.active = undefined
      }
    }
  },
  created() {
    if (this.$route.path == '/') {
      this.hoverNavMenu = true
    }
    this.active = this.$route.query.firstCategory
  },
  methods: {
    onSkip2(item, index, name) {
      this.$store.state.names = name.name
      this.active = item
      this.$router.push({ name: 'Search', query: { type: item, randomStype: Math.round(Math.random() * (99999 - 1) + 1) } })
    },
    onSkip(item, index, name) {
      this.$store.state.names = name.name
      this.active = index
      console.log('onSkip', item)
      this.$router.push({ name: 'Search', query: { firstCategory: item, randomStype: Math.round(Math.random() * (99999 - 1) + 1) } })
    },
    goSearch(firstCategory, secondCategory, thirdCategory) {
      console.log(firstCategory, secondCategory, thirdCategory)
      this.$router.push({
        path: '/search',
        query: { firstCategory: firstCategory, secondCategory: secondCategory, thirdCategory: thirdCategory, randomStype: Math.round(Math.random() * (99999 - 1) + 1) }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.floor {
  width: 1200px;
  margin: 20px auto 0;
}

.title-tou {
  margin-top: 10px;
  margin-left: 50px;
  color: #333;
  font-weight: 500;
  line-height: 40px;
  font-size: 16px;

  &:first-child {
    margin-left: 236px;
  }
}

.title-tou:hover {
  color: red !important;
  cursor: pointer;
}

.ant-carousel>>>.slick-slide {
  text-align: center;
  height: 468px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel>>>.slick-slide h3 {
  color: #fff;
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0;
}

.threeCate {
  margin: 10px 0;
  cursor: pointer;

  &:not(:last-child)::after {
    content: "";
    border-right: 1px solid #ccc;
    margin: 0 5px;
  }

  &:hover {
    color: #ff2900;
  }
}

.disc {
  display: flex;
  justify-content: left;
}

.cateFirst {
  cursor: pointer;
  width: 110px;
  text-align: center;

  &:hover {
    color: #ff2900;
  }
}

.banner1 {
  height: 501px;
  width: 2361px;
  background-position: 50%;
}

.HomeGoodsCategory {}

.xia {}

.topTabBar {
  // height: 56px;

  //.active span:hover {
  //  color: $color-main;
  //}
  .categoryTitle {
    cursor: pointer;
    background: #2e3353;
    color: #fff;
    font-size: 15px;
    height: 40px;
    overflow: visible;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      // background: #f2f2f2;
    }
  }

  .categoryTabBar {
    width: 172px;
    height: 53px;
    //line-height: 53px;
    justify-content: center;
    cursor: pointer;
  }

  ul.categoryTabBar {
    li {
      padding: 0 20px 0 20px;
      line-height: 44px;
      font-size: 14px;
    }
  }
}

.content {
  padding-top: 0;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 120;
}

@media (max-width: 1200px) {
  .main-content.content {
    width: 100%;
  }
}

ul.category {
  width: 220px;
  top: 20px;

  background-color: #fff;
  height: 368px;
  padding-left: 0;
  margin: 0;
  // border: 1px solid #fff;
  // border-top: solid 3px #fc0;
  overflow-y: scroll;
  box-shadow: 0 3px 20px rgb(9 98 234 / 10%);
  border-radius: 4px;
  display: flex;
  flex-flow: wrap;

  li {
    width: 110px;

    //height: 35px;
    z-index: 1;
    line-height: 30px;

    &:hover>.sub_category {
      display: flex;
    }
  }
}

.category>li:hover {

  // border-radius: 0 16px 16px 0;
  // background: #0962ea;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  &:hover>.cateFirst {
    color: #ff2900;
  }
}

.sub_category {
  display: none;
  position: absolute;
  flex-wrap: wrap;
  left: 220px;
  top: -4px;
  background-color: white;
  width: 600px;
  height: 368px;
  overflow-y: scroll;
  z-index: 100;

  &::-webkit-scrollbar {
    width: 0;
  }

  .ant-card {
    height: 160px;
    padding: 10px;
    margin: 20px;
  }

  .ant-card-body {
    padding: 10px;
  }

  .ant-card-hoverable:hover {
    background-color: rgba(155, 155, 155, 0.15);
  }

  ::v-deep .ant-card-meta-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
