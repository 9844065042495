/**
 * @author  XuHongli
 * @date  2022/11/15 9:39
 * @version 1.0
 * @description
 */

export const helpRouters = [
  {
    path: '/support/company',
    name: 'Company',
    meta: {
      title: '公司简介',
      layout: 'help'
    },
    component: () => import('@/views/Help/Company')
  },
  {
    path: '/support/dropShipping',
    name: 'DropShipping',
    meta: {
      title: '无忧代发',
      layout: 'help'
    },
    component: () => import('@/views/Help/DropShipping')
  },
  {
    path: '/support/SaaS',
    name: 'Saas',
    meta: {
      title: 'SaaS服务',
      layout: 'help'
    },
    component: () => import('@/views/Help/SaaS')
  },
  {
    path: '/support/FAQ',
    name: 'FAQ',
    meta: {
      title: '常见问题',
      layout: 'help'
    },
    component: () => import('@/views/Help/FAQ')
  },
  {
    path: '/support/ruleOfCoupons',
    name: 'RuleOfCoupons',
    meta: {
      title: '常见问题',
      layout: 'help'
    },
    component: () => import('@/views/Help/RuleOfCoupons')
  },
  {
    path: '/support/fullRange',
    name: 'FullRange',
    meta: {
      title: '种类齐全',
      layout: 'help'
    },
    component: () => import('@/views/Help/FullRange')
  },
  {
    path: '/support/purchaseNotes',
    name: 'PurchaseNotes',
    meta: {
      title: '购买须知',
      layout: 'help'
    },
    component: () => import('@/views/Help/PurchaseNotes')
  },
  {
    path: '/support/deliveryServices',
    name: 'DeliveryServices',
    meta: {
      name: '配送服务',
      layout: 'help'
    },
    component: () => import('@/views/Help/DeliveryServices')
  },
  {
    path: '/support/returnsProcess',
    name: 'ReturnsProcess',
    meta: {
      name: '退货流程',
      layout: 'help'
    },
    component: () => import('@/views/Help/ReturnsProcess')
  },
  {
    path: '/support/returnsPolicy',
    name: 'ReturnsPolicy',
    meta: {
      name: '退货政策',
      layout: 'help'
    },
    component: () => import('@/views/Help/ReturnsPolicy')
  },
  {
    path: '/support/descriptionOfCooperation',
    name: 'DescriptionOfCooperation',
    meta: {
      name: '合作说明',
      layout: 'help'
    },
    component: () => import('@/views/Help/DescriptionOfCooperation')
  },
  {
    path: '/support/suggestionsFeedback',
    name: 'SuggestionsFeedback',
    meta: {
      name: '合作建议及反馈',
      layout: 'help'
    },
    component: () => import('@/views/Help/SuggestionsFeedback')
  },
  {
    path: '/support/softwareDescriptionOfSaaSMall',
    name: 'SoftwareDescriptionOfSaaSMall',
    meta: {
      name: 'saas商城软件说明',
      layout: 'help'
    },
    component: () => import('@/views/Help/SoftwareDescriptionOfSaaSMall')
  },
  {
    path: '/support/POR',
    name: 'POR',
    meta: {
      name: '商品税率表',
      layout: 'help'
    },
    component: () => import('@/views/Help/POR')
  },
  {
    path: '/support/contactUs',
    name: 'ContactUs',
    meta: {
      name: '联系我们',
      layout: 'help'
    },
    component: () => import('@/views/Help/ContactUs')
  },
]
