<template>
  <div :style="{ height: typeof height === 'number' ? height + 'px' : height }" class="top-left-top">
    <div class="flex-row">
      <div class="select-ul">
        <div v-for="(item, index) in routerOptions" :key="index" :class="['']" class="list">
          <div class="title">
            <span>{{ item.name }}</span>
          </div>
          <div class="children-ul">
            <div v-for="(up, i) in item.children" :key="i" :class="[i === selectI && index === selectIndexs || $route.query.name == up.name ? 'active' : '', 'children-item']"
              @click="chaCilck(i, index, up)">
              <!-- <router-link :to="up.component">{{ up.name }} {{ i }} {{ index }} </router-link> -->
              {{ up.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/29 14:56
 * @version 1.0
 * @description
 */
import { shortcutsRouteConfigDefault } from './ShortcutsRouteConfig'
import clonedeep from 'lodash.clonedeep'
import find from 'lodash.find'
import { mapGetters } from 'vuex'
import { GetMenu } from '@/api/user/index'

export default {
  name: 'Shortcuts',
  props: {
    height: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: 'auto'
    },
    title: {
      type: String,
      default: '捷径'
    },
    home: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => {
        return shortcutsRouteConfigDefault
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect', 'isEnableCh', 'isHandpickGoods', 'isJDGoods', 'isMengDa']),
  },
  data() {
    return {
      routerOptions: [],
      selectIndexs: '',
      selectI: '',
    }
  },
  created() {
    GetMenu().then(res => {
      this.routerOptions = res.data[0].children
      this.isRoute()
      this.peiZhi()
    })
    this.childrenSelectIndex = 0
    this.selectIndex = 0
    // 根据userInfo.isEnableCh 是否开启厂家直供功能
    // if (this.isEnableCh === 1) {
    //   // 选品库的children push 进去厂家直供
    //   this.routerOptions.forEach((item, index) => {
    //     if ('选品库' === item.titleName) {
    //       item.children.push({
    //         titleName: '厂家直供',
    //         url: '/user/supplyFromManufacturer'
    //       })
    //     }
    //   })
    // }
    // this.findRouterIndex()
  },
  watch: {
    // 监听路由变化 重新寻找路径
    '$route.path'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.isRoute()
      }
    }
  },
  methods: {
    peiZhi() {
      /* 如果采购商没有开启厂家直供，把数据内的做删除 */
      if (1 !== this.isEnableCh) {
        this.routerOptions.forEach(item => {
          item.children.forEach(val => {
            if (val.component === '/user/supplyFromManufacturer') {  // 厂家直供
              delete val.name
            }
          })
        })
      }
      /* 如果采购商没有开启精选商品，把数据内的做删除 */
      if (1 !== this.isHandpickGoods) {
        this.routerOptions.forEach(item => {
          item.children.forEach(val => {
            if (val.component === '/user/handpickGoods') {  // 精选商品
              delete val.name
            }
          })
        })
      }
      /* 如果采购商没有开启京东直选，把数据内的做删除 */
      if (1 !== this.isJDGoods) {
        this.routerOptions.forEach(item => {
          item.children.forEach(val => {
            if (val.component === '/user/jdSelection') {  // 京东直选
              delete val.name
            }
          })
        })
      }
      /* 如果采购商没有开启盟大，把数据内的做删除 */
      if (1 !== this.isMengDa) {
        this.routerOptions.forEach(item => {
          item.children.forEach(val => {
            if (val.component === '/user/mengda') {  // 盟大直选
              delete val.name
            }
          })
        })
      }
    },
    isRoute () {
      let path = this.$route.path
      let pathNow = ''
      let indexNow = ''
      this.routerOptions.forEach((item, index) => {
        if (item.children.length) {
          if (item.children.findIndex((val, index) => val.component == path) !== -1) {
            pathNow = item.children.findIndex(val => val.component == path)
            indexNow = index
          }
        }
      })
      this.selectIndexs = indexNow
      this.selectI = pathNow
    },
    chaCilck(i, index, item) {
      this.selectI = i
      this.selectIndexs = index
      this.$router.push({ path: item.component })
    },
    // 寻找路径下标方法
    findRouterIndex() {
      this.selectIndex = 0
      find(this.routerOptions, (options) => {
        this.selectIndex++
        this.childrenSelectIndex = 0
        return find(options.children, (item) => {
          this.childrenSelectIndex++
          return item.url === this.$route.path
        })
      })
      this.selectIndex--
      this.childrenSelectIndex--

      if (this.$route.path === '/user/userCenter') {
        this.selectIndex = null
        this.childrenSelectIndex = null
      }
      // 额外判断表格订单详情页面
      if (this.$route.path.indexOf('/user/orderTableDetail') > -1) {
        this.selectIndex = 0
        this.childrenSelectIndex = 0
      }
      // 额外判读 选品库 详情表格页面
      if (this.$route.path.indexOf('/user/ShopLibrary/GroupDetail') > -1) {
        this.selectIndex = 1
        this.childrenSelectIndex = 0
      }
      // 额外判读 财务管理-资金概栏充值/提现（页面）
      if (this.$route.path.indexOf('/user/fina/recharge') > -1 || this.$route.path.indexOf('/user/fina/withdrawDeposit') > -1) {
        this.selectIndex = 2
        this.childrenSelectIndex = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  display: inline;
  width: 190px;
}

.top-left-top {
  min-height: 100%;
  display: flex;
  background-color: #fff;
}

.select-ul {
  width: 170px;
  margin: 0;
  padding: 0;
  cursor: pointer;

  .list {
    padding: 15px 20px;
    border-bottom: 1px solid #f3f3f3;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    color: #333;
  }
}

.active {
  color: #c81623;
  font-weight: 600;
}

.children-ul {
  .children-item {
    font-size: 14px;
    cursor: pointer;
    line-height: 36px;

    &.active {
      color: #c81623;
      font-weight: 600;
    }
  }

  a {
    height: 100%;
    display: block;
    width: 100%;
    color: $color-main;

    &:hover {
      color: #c81623;
    }
  }
}
</style>
