<template>
  <div>
    <div style="background:#fff;">
      <LoginOrRegisterHeader></LoginOrRegisterHeader>
    </div>
    <router-view />
    <Footer :has-ad-image="false"></Footer>
  </div>
</template>

<script>
import HeadWarp from '@/Layouts/Default/Header/HeadWarp'
import Footer from '@/Layouts/components/Footer/index.vue'
import LoginOrRegisterHeader from '@/Layouts/components/LoginOrRegisterHeader/index.vue'
/**
 * @author  XuHongli
 * @date  2022/7/25 9:20
 * @version 1.0
 * @description
 */
export default {
  name: 'LoginDefault',
  components: { LoginOrRegisterHeader, Footer, HeadWarp }
}
</script>

<style scoped>

</style>
