/**
 * @author  XuHongli
 * @date  2022/7/12 14:32
 * @version 1.0
 * @description  加载本地配置项
 */

import store from '@/store'
import storage from 'store'

import {
  APP_LANGUAGE,
} from '@/store/mutation-types'

export default function Initializer () {
  store.dispatch('setLang', storage.get(APP_LANGUAGE, 'zh-CN'))
}
