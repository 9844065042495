<template>
  <div>
    <div :class="isIndex == 1 ? 'listClass1' : 'listClass'" class="listClass ">
      <div v-for="(item, index) in list.slice((current - 1) * size, current * size)" :key="index" :class="isIndex == 1 ? 'listC' : 'listClassItem'"
        class="listClassItem" @click.stop="commodityClisck(item)">
        <div class="card-hezi">
          <img v-if="isIndex != 1" :src="item.imgUrl" alt="" />
          <el-image v-else :src="item.imgUrl" class="img" lazy></el-image>
        </div>
        <div class="hezi">
          <a-tooltip :title="item.name" placement="left">
            <div class="name">{{ item.name }}</div>
          </a-tooltip>
          <div v-if="userInfo.infoProfect !== 1" class="goods_price">
            <div v-if="Object.keys(userInfo).length == 0 || userInfo.infoProfect === 0" class="price"
              @click.stop="$router.push($tp().IS_OPEN == 1 ? '/user/login' : '/login')">
              {{ Object.keys(userInfo).length == 0 ? '价格登录可见' : userInfo.infoProfect === 0 ? '账号信息未完善' : '账号信息待审核' }}
            </div>
            <div v-else-if="userInfo.infoProfect == 2" class="price">
              {{ userInfo.infoProfect == 2 ? '账号信息待审核' : '' }}
            </div>
            <div v-else-if="userInfo.infoProfect == 3" class="price">
              {{ userInfo.infoProfect == 3 ? '审核未通过' : '' }}
            </div>
          </div>
          <div v-else class="goods_price">
            <div class="costprice">
              <div style="font-weight: 0;color: #f50;font-size: 10px;">{{ item.tradePrice ? item.tradePrice.toFixed(2) :
                '0.00' }}</div>
              <div>批发价</div>
            </div>
            <div class="profit">
              <div style="color: #2ba7ff;font-size: 10px;">{{ (((item.marketPrice - item.tradePrice) / item.tradePrice) *
                100).toFixed(2) }}%</div>
              <div>利润率</div>
            </div>
            <div>
              <div style="font-weight: 0;color: #f50;font-size: 10px;">{{ (item.marketPrice - item.tradePrice).toFixed(2)
              }}</div>
              <div>利润</div>
            </div>
          </div>
          <div class="flex-between sales-info">
            <div class="sale_num">
              <span>销</span><span>{{ item.sale || 0 }}</span>
            </div>
            <div v-if="userInfo.infoProfect == 1" style="color: #666;">市场价:<span style="color: #ff5300;">￥{{
              item.marketPrice ? item.marketPrice.toFixed(2) : '0.00' }}</span></div>
            <div v-if="item.tradeType" class="tag">{{ tp[item.tradeType] }}</div>
          </div>
          <!--          <div v-if="Object.keys(userInfo).length != 0" class="flex-between flexs ">
            <div v-if="userInfo.infoProfect == 1" class="price">le="font-size:10px;">￥</span><span style="font-size:18px">{{ item.tradePrice.toFixed(2) }}</span>
            </div>
            <div style="display: flex;align-content: center;">
              <div v-if="Object.keys(userInfo).length != 0 && userInfo.infoProfect === 1" style="margin-right: 10px">
                <a-icon
                  :style="item.isFavorite === 1 ? 'color: #db4100' : ''"
                  :theme="item.isFavorite === 1 ? 'filled' : 'outlined' "
                  style="font-size:18px;margin-right:5px;margin-top: 2px;"
                  two-tone-color="#db4100"
                  type="heart"
                  @click.stop="handleHeart(item,index)" />
              </div>
              <span
                v-if="Object.keys(userInfo).length !== 0 && userInfo.infoProfect === 1"
                @click.stop="handleAddGoods(item, index)" >
                {{ item.isPuint === 1 || (isGo && isPuintIndex === index) ? '已入库' :'一键入库' }}
              </span>
            </div>
          </div>-->
          <div v-if="Object.keys(userInfo).length !== 0 && userInfo.infoProfect === 1"
            :style="isIndex == 1 ? 'bottom: 127px;' : 'top: 52%;'" class="goods_btn">
            <div class="markPrice" @click.stop="handleAddGoods(item, index)">
              {{ item.isPuint === 1 || (isGo && isPuintIndex === index) ? '已入库' : '一键入库' }}
            </div>
            <div class="line"></div>
            <div v-if="Object.keys(userInfo).length != 0 && userInfo.infoProfect === 1" class="shopuc"
              @click.stop="handleHeart(item, index)">
              <!--                :style="item.isFavorite === 1 ? 'color: #db4100' : ''"-->
              <!--                :theme="item.isFavorite === 1 ? 'filled' : 'outlined' "-->
              <a-icon style="font-size:15px;margin-right:5px;margin-top: 2px;vertical-align: sub;" theme="outlined"
                two-tone-color="#db4100" type="heart" />{{ item.isFavorite === 1 ? '已收藏' : '收藏' }}
            </div>
          </div>
          <div :class="'hover'">
            <div class="xiang_si">
              <a-button class="findBtn" shape="round" type="danger" @click.stop="onSimilarity(item)">找相似</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { favoriteProduct, getFindSimilarity } from '@/api/Product'

export default {
  props: {
    // 商品数量
    size: {
      type: Number,
      required: true
    },
    // 商品页面
    current: {
      type: Number,
      default: 1
    },
    list: {
      type: [Array, String],
      default: () => []
    },
    // eslint-disable-next-line vue/require-prop-type-constructor
    isIndex: ''
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
    tp() {
      return {
        1: '保税直供',
        2: '完税进口',
        3: '国内贸易',
        4: '香港直邮',
        5: '海外直邮'
      }
    },

  },
  data() {
    return {
      isGo: false,
      isPuintIndex: 0
    }
  },
  methods: {
    onSimilarity(val) {
      // 随机生成数字
      var random = Math.random() * (10 - 1) + 1
      let randomStype = Math.round(Math.random() * (99999 - 1) + 1)
      this.$router.push({ path: '/search', query: { like: val.id, randomStype: randomStype } })
    },
    commodityClisck(item) {
      let PAGE_URL = window.origin + '/commodity/' + item.id
      window.open(PAGE_URL, '_blank')
    },
    handleHeart(item, index) {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        favoriteProduct(item.id).then((res) => {
          item.isFavorite === 1 ? item.isFavorite = 0 : item.isFavorite = 1
          console.log('handleHeart', item.isFavorite)

        })
      }
    },
    handleAddGoods(item, index) {
      const that = this
      const ids = [item.id ? item.id : null]
      if (item.isPuint == 1 || this.isGo) {
        this.$router.push('/user/shopLibrary')
      } else {
        this.$AddGroupModal({
          ids,
          handleOk: (val) => {
            if (val === 'ok') {
              if (val === 'ok') {
                item.isPuint = 1
              }
            } else {
              this.$emit('handleAddGoods')
            }
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.goods_btn {
  width: 100%;
  height: 30px;
  position: absolute;
  left: 0;
  display: none;
  justify-content: space-between;
  align-items: center;
  opacity: .8;
  background: rgb(255, 101, 74);
}

.shopuc {
  border: none;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  border-radius: 0;
  width: 103px;
  height: 30px;
  line-height: 30px;
  background: rgba(255, 101, 74, .8);
  padding: 0;
}

.line {
  width: 1.1px;
  height: 27px;
  background: #ffd9d9;
}

.markPrice {
  width: 103px;
  height: 30px;
  font-size: 12px;
  background: rgba(255, 101, 74, .8);
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.findBtn {
  background-color: $color-orange;
  height: 30px;
  font-weight: 400;
  color: white;
  width: 100px;
}

.hezi {
  padding: 10px;

  .goods_price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 170px;
    background: #f2f8ff;
    border-radius: 4px 4px 4px 4px;
    margin: 10px 0;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666;

    .costprice,
    .profit,
    .goods_price>div {
      text-align: center;
    }
  }
}

.flexs {
  justify-content: space-between;
}

.tps {
  display: flex;
  align-content: center;
  position: relative;
}

.hover {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 80px;
  line-height: 80px;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 300%);
}

.heart {
  font-size: 22px;
  bottom: 10px;
  right: 10px;
}

.hover {
  opacity: 0
}

.listClassItem:hover {
  .hover {
    opacity: 1;
    transition: all .5s;
    display: block;
  }

  .goods_btn {
    transition: all .5s;
    display: flex;
  }
}

.listClass {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.listClass1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.listClass2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.tag {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #e31436;
  padding: 1px 5px;
  color: #fff;
  font-size: 11px;
  background: #ff5d45;
  border-radius: 3px
}

.sales-info {
  color: #e46a1a;
  font-size: 12px;
  //height: 20px;
  //line-height: 20px;
  display: flex;
  justify-content: space-between;

  .sale_num {
    display: flex;

    span:first-child {
      //width: 19px;
      //height: 20px;
      padding: 0 3px;
      background: linear-gradient(90deg, #ff4f42, #ff743c);
      border-radius: 2px 0 0 2px;
      font-size: 10px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #fff;
      display: block;
    }

    span:last-child {
      //height: 20px;
      border-radius: 0 2px 2px 0;
      opacity: 1;
      border-top: 1px solid #fd7100;
      border-right: 1px solid #fd7100;
      border-bottom: 1px solid #fd7100;
      padding: 0 3px;
      font-size: 10px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #fd7100;
    }
  }
}

.price {
  color: red;
  font-size: 14px;
  text-align: left;
}

.flex-between {
  display: flex;
  align-items: center;
}

.listClassItem {
  width: 190px;
  height: 310px;
  background: #fff;
  text-align: center;
  margin-top: 12px;
  margin-right: 12px;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
}

.listClassItem:hover {
  cursor: pointer;
  border: 1px solid #e31436;
  // box-shadow: 0px 1px 4px 5px rgb(235, 229, 232);
}

.card-hezi {
  width: 100%;
  height: 190px;
  overflow: hidden;
}

.listClassItem img:hover {
  transform: scale(1.1);
  border-radius: 5px 5px 0 0;
}

img {
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s ease .1s;
}

.name {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.listC {
  width: 225px;
  height: 355px;
  background: #fff;
  text-align: center;
  margin-top: 12px;
  margin-right: 18px;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
}

.listC .card-hezi {
  height: 225px;
}
</style>

