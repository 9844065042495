<template>
  <a-drawer
    :closable="false"
    :visible="visible"
    placement="right"
    title="我的收藏"
    width="800px"
    @close="closeDrawer"
  >
    <a-spin :spinning="loading" class="pb-20" tip="Loading...">
      <div class="flex-row" style="flex-direction: row-reverse">
        <a-button class="ml-10" size="small" type="info" @click="deleteGoods">一键删除</a-button>
        <a-button
          :type="checkedList.length === 0 ? 'primary' : 'danger' "
          size="small"
          @click="selectAll"
        >
          {{ checkedList.length === 0 ? '选中全部' : '取消全部' }}
        </a-button>
      </div>
      <a-checkbox-group v-model="checkedList">
        <div class="goods">
          <a-card
            v-for="(item, index) in goods"
            :key="index + r"
            :hoverable="true"
            class="goodsCard"
            @click="() => { $router.push('/commodity/' + item.spuId);$emit('input', false) }">
            <div @click.stop="{}">
              <a-checkbox :value="item.spuId" class="checkbox"></a-checkbox>
            </div>
            <img
              slot="cover"
              :src="item.supplyProduct.imgUrl"
              alt="example"
            />
            <a-card-meta :title="item.supplyProduct.name">
              <template slot="description">
                <div class="card">
                  <span style="color: #d11514;font-size: 16px;font-weight: 600;">￥{{ item.supplyProduct && item.supplyProduct.tradePrice ? item.supplyProduct.tradePrice.toFixed(2) : '0.00' }}</span>
                </div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
      </a-checkbox-group>
    </a-spin>
    <div class="action flex-row flex-col-center flex-row-between">
      <div>
        <span style="text-align: left">
          已选择 <span class="text-danger">{{ checkedList.length }}</span> 件商品
        </span>
      </div>

      <div>
        <a-button style="margin-right: 8px" @click="closeDrawer">
          取消
        </a-button>
        <a-button type="primary" @click="handleAddGoods">
          一键入库
        </a-button>
      </div>
    </div>
    <DeleteFavoriteGoodsModal v-model="ShowDeleteFavoriteGoodsModal" :ids="checkedList" @ok="init();checkedList=[]"></DeleteFavoriteGoodsModal>
  </a-drawer>
</template>

<script>
import ShowDrawer from '@/mixin/ShowDrawerMixin'
import { mapGetters } from 'vuex'
import { getUserFavoriteList } from '@/api/Favorite'
import DeleteFavoriteGoodsModal from '@/Layouts/components/FavoritePanelDrawer/DeleteFavoriteGoodsModal'

/**
 * @author  XuHongli
 * @date  2022/12/1 15:45
 * @version 1.0
 * @description 我的收藏弹窗
 */
export default {
  name: 'FavoritePanelDrawer',
  components: { DeleteFavoriteGoodsModal },
  mixins: [ ShowDrawer ],
  computed: {
    ...mapGetters(['userInfo', 'infoProfect', 'updateFavoriteListCount']),
  },
  data() {
    return {
      // 加载中
      loading: true,
      // 收藏的商品数据
      goods: [],
      r:Math.random() *1000,
      // 选中的列表
      checkedList: [],
      total: 0,
      queryParam: {
        current: 1,
        size: 10000,
        type: 2 // 用户足迹类型 1:足迹 2:收藏
      },
      // 加入分组弹窗
      ShowAddGroupModal: false,
      // 一键删除收藏 弹窗
      ShowDeleteFavoriteGoodsModal: false

    }
  },
  created() {
    this.$watch('updateFavoriteListCount', () => {
      this.init()
    }, { immediate: true })
    // 监听 是否填写完信息
    this.$watch('infoProfect', () => {
      this.init()
    }, { immediate: true })
    // 监听 收藏总数 向上传递数据
    this.$watch('total', () => {
      this.$emit('total', this.total)
    }, { immediate: true })
  },
  methods: {
    init() {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        getUserFavoriteList(this.queryParam).then((res) => {
          this.$emit('amount', res.data.total)
          this.loading = false
          this.goods = res.data.records
          this.r = Math.random() *1000
          this.total = res.data.total
        })
      }
    },
    success() {
      this.init()
      this.checkedList = []
    },
    handleAddGoods() {
      if (this.checkedList.length === 0) {
        this.$notification.error({
          message: `未选择产品`,
          description:
            `您还未选择产品.`,
        })
      } else {
        this.$AddGroupModal({ ids: this.checkedList, handleOk: () => { this.checkedList=[] }})
      }
    },
    // 选中全部 或 取消全部
    selectAll() {
      if(this.checkedList.length === 0){
        this.checkedList = this.goods.map((item) => {
          return item.spuId
        })
      } else {
        this.checkedList = []
      }
    },
    // 一键删除
    deleteGoods() {
      if (this.checkedList.length === 0) {
        this.$notification.error({
          message: `删除失败`,
          description:
            `您还未选择产品.`,
        })
      } else {
        this.ShowDeleteFavoriteGoodsModal = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-card-meta-title{
  font-size: 14px;
  margin-bottom: 2px;
}
::v-deep .ant-card-body{
  padding: 12px;
}
::v-deep .ant-drawer-body{
  min-height: 94%;
}

.action {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}

.card{
  display: flex;
  justify-content: space-between;
}



.goods{
  display: flex;
  flex-wrap: wrap;
  .goodsCard {
    position: relative;
    margin: 5px 10px;
    width: 162px;
    border-radius: 5px;
    img{
      width: 150px;
      height: 150px;
      padding: 5px 0 0 5px;
      border-radius: 10px;
      object-fit: cover;
    }

    .btn{
      display: flex;
      justify-content: right;
      .img{
        width: 40px;
        height: 40px;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .checkbox{
    position: absolute;
    right: 10px;
    top: 10px;
    transform: scale(1.5);
  }
}

</style>
