/**
 * @author  XuHongli
 * @date  2022/7/7 17:05
 * @version 1.0
 * @description 工具方法合集
 */

import CryptoJS from 'crypto-js'

/**
 * @description 判断是否是IE浏览器
 * @return {boolean}
 */
/** 请求头 */
import { getToken } from '@/utils/cookie'

/** 图片上传限制 */
import { message } from 'ant-design-vue'
import axios from 'axios'

/** 文件上传路径 */
export const upLoadObj = {
  action: process.env.NODE_ENV === 'development' ? '/api/upms/file/settled-upload?fileType=img' : '/upms/file/settled-upload?fileType=img'
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 1000000000000).toString().substring(0, len || 4)
  if (date) random = random + Date.now()
  return random
}

/**
 * @description 删除加载动画
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

export const headers = {
  Authorization: 'Bearer ' + getToken() || ''
}

/** 文件转64 */
export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

/** 文件转64callback */
export function getBaseCallback64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export function beforeUpload (file) {
  console.log(file, 'file beforeUpload')
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('只能上传JPG、PNG图片!')
  }
  const isLt2M = file.size / 1024 / 1024 <100
  if (!isLt2M) {
    message.error('图片不能超过100MB!')
  }
  return isJpgOrPng && isLt2M
}

/**
 * 加密
 * @param params { {data , type ,param ,key} }
 * @return {any}
 */
export const encryption = (params) => {
  // eslint-disable-next-line prefer-const
  let { data, type, param, key } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      const data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      const iv = key
      // 加密
      const encrypted = CryptoJS.AES.encrypt(
        data,
        key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding
        })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

export const getFile = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer'
    }).then(data => {
      resolve(data.data)
    }).catch(error => {
      reject(error.toString())
    })
  })
}

/**
 * @description 从HTML代码中提取出Src地址
 * @param richText {string}
 * @return {Array<string>}
 */
export function getImgSrc(richText) {
  const imgList = []
  richText.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
    console.log(capture)
    imgList.push(capture)
  })
  return imgList
}

/**
 * @description 限制输入整数的位数，小数点位数
 */
export function limitNumTool(value, figure) {
  let str =
    ('' + value)                                            // 转成字符串
      .replace(/[^\d^\.]+/g, '')     // 把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, '$1')	     // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, '0.')         // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || ''        // 最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
  if (str.indexOf('.') > -1) {
    // 小数后两位
    str = str.slice(0, Number(figure) + 3)
  } else {
    str = str.slice(0, figure)
  }
  return str
}

/**
 * 日期格式化
 */
export function dateFormat(date) {
  let format = 'yyyy-MM-dd hh:mm:ss'
  if (date != 'Invalid Date') {
    var o = {
      'M+': date.getMonth() + 1, //month
      'd+': date.getDate(), //day
      'h+': date.getHours(), //hour
      'm+': date.getMinutes(), //minute
      's+': date.getSeconds(), //second
      'q+': Math.floor((date.getMonth() + 3) / 3), //quarter
      'S': date.getMilliseconds() //millisecond
    }
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (var k in o)
      if (new RegExp('(' + k + ')').test(format))
        format = format.replace(RegExp.$1,
          RegExp.$1.length == 1 ? o[k] :
            ('00' + o[k]).substr(('' + o[k]).length))
    return format
  }
  return ''
}

/**
 * 删除URL中指定search参数,会将参数值一起删除
 * @param {string} url 地址字符串
 * @param {array} aParam 要删除的参数key数组，如['name','age']
 * @return {string} 返回新URL字符串
 */
export const editUrl = (url,aParam) =>{
  aParam.forEach(item => {
    const fromindex = url.indexOf(`${item}=`) //必须加=号，避免参数值中包含item字符串
    if (fromindex !== -1) {
      // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
      const startIndex = url.indexOf('=', fromindex)
      const endIndex = url.indexOf('&', fromindex)
      const hashIndex = url.indexOf('#', fromindex)

      let reg;
      if (endIndex !== -1) { // 后面还有search参数的情况
        const num = endIndex - startIndex
        reg = new RegExp(`${item}=.{${num}}`)
        url = url.replace(reg, '')
      } else if (hashIndex !== -1) { // 有hash参数的情况
        const num = hashIndex - startIndex - 1
        reg = new RegExp(`&?${item}=.{${num}}`)
        url = url.replace(reg, '')
      } else { // search参数在最后或只有一个参数的情况
        reg = new RegExp(`&?${item}=.+`)
        url = url.replace(reg, '')
      }
    }
  });
  const noSearchParam = url.indexOf('=')
  if( noSearchParam === -1 ){
    url = url.replace(/\?/, '') // 如果已经没有参数，删除？号
  }
  return url
}
