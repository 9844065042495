/**
 * @author  XuHongli
 * @date  2022/11/15 14:01
 * @version 1.0
 * @description
 */

export const keywords = [
  {
    name: '取暖器',
    color: 'red',
  },
  {
    name: '护手霜',
  },
  {
    name: '雅诗兰黛',
  },
  {
    name: 'SK-II',
  },
  {
    name: '美可卓',
  },
  {
    name: 'A2',
  },
  {
    name: '保健',
  },
  {
    name: '燕窝',
  },
  {
    name: '红酒',
  },
]
