<template>
  <div class="robDis">
    <div class="left-box">
      <div class="title">每日必抢</div>
      <img alt="" src="https://images.houniao.hk/wstmart/home/view/default/public/index/images/thunder.png" />
      <div class="desc">距离本场结束还剩</div>
      <div class="time">
        <div class="time-item">{{ state.h }}</div>
        <div style="font-size: 30px; margin: 0px 6px;">:</div>
        <div class="time-item">{{ state.m }}</div>
        <div style="font-size: 30px; margin: 0px 6px;">:</div>
        <div class="time-item">{{ state.s }}</div>
      </div>
    </div>
    <!-- 左边轮播 -->
    <div class="robtop">
<!--      优化-->
      <el-carousel :autoplay="false" :interval="3500" height="325px" indicator-position="none" style="width:999px;">
        <el-carousel-item v-for="k in Math.ceil(timeList.length / 5)" :key="k">
          <listCard :current="k" :list="timeList" :size="5" @handleAddGoods="handleAddGoods"></listCard>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import listCard from '@/components/listCard/card'
import OwlCarouselVue from '@/components/OwlCarousel/OwlCarousel.vue'
export default {
  components:{listCard,OwlCarouselVue},
  props:{
    timeList:{
      type:Array,
      default:() => []
    },
  },
  data() {
    return {
      state:{
        h:'',
        me:'',
        s:''
      },
    }
  },
  mounted(){
    this.componentDidMount()
  },
  methods:{
    handleAddGoods() {
      this.$emit('handleAddGoodsg')
    },
    addZero (time ){
      return time  < 10 ? '0' + time : time
    },
    // 倒计时
    componentDidMount() {
        let timer = setInterval(() => {
          let nightTime = new Date(new Date().toLocaleDateString()).getTime() +24 * 60 * 60 * 1000 -1
          let newTime = new Date().getTime()
          let lefttime = parseInt((nightTime- newTime) / 1000)
          if(lefttime<=0){
             clearInterval(timer)
          }
          let h = parseInt(lefttime / (60 * 60) % 24)
          let m = parseInt(lefttime / 60 % 60)
          let s = parseInt(lefttime % 60)
          this.state.h = this.addZero(h)
          this.state.m = this.addZero(m)
          this.state.s = this.addZero(s)
        }, 1000)
    }



  }
}
</script>

<style lang="scss" scoped>

  .robtop{
    margin-top: -11px;
    display: flex;
    padding-left: 10px;
  }
  .robDis{
    display: flex;
    width: 1200px;

    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .left-box{
        background-color: #e12021;
        color: #fff;
        cursor: pointer;
        height: 310px;
        overflow: hidden;
        text-align: center;
        width: 200px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .title{
          color: #fff;
          font-size: 34px;
          height: 40px;
          line-height: 40px;
          margin-top: 25px;
        }
        img{
          width: 56px;
          height: 56px;
          margin-top: 25px;
          margin-bottom: 25px;
        }
        .desc{
          font-size: 16px;
          margin-bottom: 25px;
        }
        .time{
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .time-item{
                background-color: #2f3430;
                float: left;
                height: 40px;
                text-align: center;
                min-width: 40px;
                font-size: 20px;
                font-weight: 700;
                line-height: 40px;
                position: relative;
            }
        }
    }
  }
</style>
