import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/Home.vue'
import { UserRouters } from '@/router/userRouters'
import { helpRouters } from '@/router/helpRouters'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/**
 * 默认路由表
 */
export const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: ''
    },
    component: Home
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      title: ''
    },
    component: () => import('@/views/Search/index')
  },
  {
    path: '/prefecture',
    name: 'Prefecture',
    meta: {
      title: ''
    },
    component: () => import('@/views/Precinct/index')
  },
  {
    path: '/store',
    name: 'Store',
    meta: {
      layout: 'ShopIndex',
      title: ''
    },
    component: () => import('../views/store.vue')
  },
  {
    path: '/commodity/:id(\\d+)',
    name: 'commodity',
    component: () => import('@/views/GoodsDetail/commodity.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '',
      layout: 'login'
    },
    component: () => import('@/views/Login/Login')
  },
  {
    path: '/registerTypeSelect',
    name: 'RegisterTypeSelect',
    meta: {
      layout: 'login'
    },
    component: () => import('@/views/Login/RegisterTypeSelect.vue')
  },
  {
    path: '/registerSteps',
    name: 'RegisterSteps',
    meta: {
      layout: 'login'
    },
    component: () => import('@/views/Login/RegisterSteps')
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    meta: {
      layout: 'login'
    },
    component: () => import('@/views/Login/ForgetPassword/ForgetPassword')
  },
  /* 协议路由配置 */
  {
    path: '/platform/agreement',
    name: 'Platform',
    meta: {
      title: '平台协议',
    },
    component: () => import('@/views/Agreement/Platform')
  },
  {
    path: '/service/agreement',
    name: 'Service',
    meta: {
      title: '服务协议',
    },
    component: () => import('@/views/Agreement/Service')
  },
]

/**
 * 异常错误页面 放在最后
 * @type {[{redirect: string, path: string, component: (function(): Promise<{readonly default?: {name: string, mounted(): void}}>), hidden: boolean}]}
 */
export const NotFoundRouters = [
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: 'NotFound',
      layout: 'null'
    },
    component: () => import('@/views/Error/404'),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes: [...routes, ...UserRouters, ...NotFoundRouters, ...helpRouters],
  mode: process.env.NODE_ENV === 'pre' ? 'history' : 'history',
  scrollBehavior: () => ({ y: 0 })
})

export default router
