<template>
  <div>
    <div v-for="(up, is) in cateUplist" :key="is" class="floor">
      <div class="floor-popular ">
        <div style="display: flex;">
          <div class="floor-title">
            <h3 :style="'background:' + up.color" class="cus" @click="moreBTN(up.oneCateId)">{{ up.name }}</h3>
            <div class="child-cats">
              <span v-for="item in up.categoryList?.slice(0, 10)" :key="item.id" class="cus"
                @click="goSearch(item.parentId, item.id)">
                {{ item.name }}
              </span>
            </div>
            <!--          <div class="more" @click="moreBTN(resc.name)">更多》</div>-->
          </div>
        </div>
        <div class="">
          <div class="floor-con">
            <div class="left">
              <img :src="up.imgUrl" alt="" style="cursor: pointer;" />
              <div class="brand">
                <div v-for="re in up.brandList" :key="re.id" class="brand-item"
                  @click="$router.push({ path: '/search?type=1&interiorBrandId=' + re.id })">
                  {{ re.name }}
                </div>
              </div>
            </div>
            <!-- 商品列表 -->
            <div class="middle">

              <div class="listClass">
                <div v-for="(item, index) in up.products" :key="item.id" class="listClassItem"
                  @click.stop="commodityClisck(item)">
                  <div class="card-hezi">
                    <img :src="item.imgUrl" alt="" />
                  </div>
                  <div class="hezi">
                    <a-tooltip :title="item.name" placement="left">
                      <div class="names">{{ item.name }}</div>
                    </a-tooltip>
                    <div v-if="userInfo.infoProfect !== 1" class="goods_price">
                      <div v-if="Object.keys(userInfo).length == 0 || userInfo.infoProfect === 0" class="price"
                        @click.stop="$router.push('/login')">
                        {{ Object.keys(userInfo).length == 0 ? '价格登录可见' : userInfo.infoProfect === 0 ? '账号信息未完善' :
                          '账号信息待审核' }}
                      </div>
                      <div v-else-if="userInfo.infoProfect == 2" class="price">
                        {{ userInfo.infoProfect == 2 ? '账号信息待审核' : '' }}
                      </div>
                      <div v-else-if="userInfo.infoProfect == 3" class="price">
                        {{ userInfo.infoProfect == 3 ? '审核未通过' : '' }}
                      </div>
                    </div>
                    <div v-else class="goods_price">
                      <div class="costprice">
                        <div style="font-weight: 0;color: #f50;font-size: 10px;">{{ item.tradePrice ?
                          item.tradePrice.toFixed(2) : '0.00' }}</div>
                        <div>批发价</div>
                      </div>
                      <div class="profit">
                        <div style="color: #2ba7ff;font-size: 10px;">{{ (((item.marketPrice - item.tradePrice) /
                          item.tradePrice) * 100).toFixed(2) }}%</div>
                        <div>利润率</div>
                      </div>
                      <div>
                        <div style="font-weight: 0;color: #f50;font-size: 10px;">{{ (item.marketPrice -
                          item.tradePrice).toFixed(2) }}</div>
                        <div>利润</div>
                      </div>
                    </div>
                    <div class="flex-between sales-info">
                      <div class="sale_num">
                        <span>销</span><span>{{ item.sale || 0 }}</span>
                      </div>
                      <div v-if="userInfo.infoProfect == 1" style="color: #666;">市场价:<span style="color: #ff5300;">￥{{
                        item.marketPrice ? item.marketPrice.toFixed(2) : '0.00' }}</span></div>
                      <div v-if="item.tradeType" class="tags">{{ tp[item.tradeType] }}</div>
                    </div>
                    <div v-if="Object.keys(userInfo).length !== 0 && userInfo.infoProfect === 1" class="goods_btn">
                      <div class="markPrice" @click.stop="handleAddGoods(item, index)">
                        {{ item.isPuint === 1 || (isGo && isPuintIndex === index) ? '已入库' : '一键入库' }}
                      </div>
                      <div class="line"></div>
                      <div v-if="Object.keys(userInfo).length != 0 && userInfo.infoProfect === 1" class="shopuc"
                        @click.stop="handleHeart(item, index)">
                        <!--                :style="item.isFavorite === 1 ? 'color: #db4100' : ''"-->
                        <!--                :theme="item.isFavorite === 1 ? 'filled' : 'outlined' "-->
                        <a-icon style="font-size:15px;margin-right:5px;margin-top: 2px;vertical-align: sub;"
                          theme="outlined" two-tone-color="#db4100" type="heart" />{{ item.isFavorite === 1 ? '已收藏' : '收藏'
                          }}
                      </div>
                    </div>
                    <div :class="'hover'">
                      <div class="xiang_si">
                        <a-button class="findBtn" shape="round" type="danger"
                          @click.stop="$router.push({ path: '/search', query: { like: item.id, randomStype: Math.round(Math.random() * (99999 - 1) + 1) } })">找相似</a-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- 右边栏 -->
      </div>
      <div class="right">
        <h1>热销推荐</h1>
        <!--      优化-->
        <el-carousel :autoplay="false" height="620px" indicator-position="outside">
          <el-carousel-item v-for="k in Math.ceil(up.brandIds.length / 7)" :key="k">
            <div v-for="(item, index) in up.brandIds.slice((k - 1) * 7, k * 7)" :key="index" class="goods-item" @click.stop="commodityClisck(item)">
              <img :src="item.imgUrl" alt="" style="width:54px;height:54px;">
              <div>
                <div class="name" style=" margin-left: 5px;">
                  {{ item.name }}
                </div>
                <div v-if="!Object.keys(userInfo).length || userInfo.infoProfect === 0" class="price"
                     style=" margin-left: 5px;" @click.stop="$router.push('/login')">
                  <span style="font-size:10px">
                    {{ Object.keys(userInfo).length === 0 ? '价格登录可见' : userInfo.infoProfect === 0 ? '账号信息未完善' : '账号信息待审核' }}
                  </span>
                </div>
                <div v-else class="price" style=" margin-left: 5px;">
                  <span style="font-size:10px">{{ getPrice(item) }}</span>
                </div>
              </div>
            </div>
            <div class="seemore" @click="$router.push({ path: '/search' })">查看更多销量</div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { favoriteProduct } from '@/api/Product'

export default {
  name: 'CategoryListings',
  props: {
    cateUplist: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      isGo: false,
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
    tp() {
      return {
        1: '保税直供',
        2: '完税进口',
        3: '国内贸易',
        4: '香港直邮',
        5: '海外直邮'
      }
    },
  },
  created() {},
  methods: {
    getPrice(item) {
      if (this.userInfo.infoProfect === 2) {
        return '账号信息待审核'
      } else {
        if (item && item.tradePrice) {
          return '￥' + item.tradePrice.toFixed(2)
        } else {
          return '￥0.00'
        }
      }
    },
    commodityClisck(item) {
      let PAGE_URL = window.origin + '/commodity/' + item.id
      window.open(PAGE_URL, '_blank')
    },
    moreBTN(val) {
      this.$router.push({ path: `/search?type=${1}&firstCategory=${val}` })
    },
    goSearch(firstCategory, secondCategory, thirdCategory) {
      this.$router.push({
        path: '/search',
        query: { type: 1, firstCategory: firstCategory, secondCategory: secondCategory, thirdCategory: thirdCategory }
      })
    },
    handleHeart(item, index) {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        favoriteProduct(item.id).then((res) => {
          item.isFavorite === 1 ? item.isFavorite = 0 : item.isFavorite = 1
          console.log('handleHeart', item.isFavorite)

        })
      }
    },
    handleAddGoods(item) {
      const that = this
      const ids = [item.id ? item.id : null]
      if (item.isPuint == 1 || item.isGo) {
        this.$router.push('/user/shopLibrary')
      } else {
        this.$AddGroupModal({
          ids,
          handleOk: (val) => {
            if (val === 'ok') { item.isPuint = 1 }
            this.$emit('handleAddGoods')
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.shopuc {
  border: none;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  border-radius: 0;
  width: 103px;
  height: 30px;
  line-height: 30px;
  background: rgba(255, 101, 74, .8);
  padding: 0;
}

.tags {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #e31436;
  padding: 1px 5px;
  color: #fff;
  font-size: 11px;
  background: #ff5d45;
  border-radius: 3px
}

.markPrice {
  width: 103px;
  height: 30px;
  font-size: 12px;
  background: rgba(255, 101, 74, .8);
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.line {
  width: 1.1px;
  height: 27px;
  background: #ffd9d9;
}

.goods_btn {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 52%;
  left: 0;
  display: none;
  justify-content: space-between;
  align-items: center;
  opacity: .8;
  background: rgb(255, 101, 74);
}

.sales-info {
  color: #e46a1a;
  font-size: 12px;
  //height: 20px;
  //line-height: 20px;
  display: flex;
  justify-content: space-between;

  .sale_num {
    display: flex;

    span:first-child {
      //width: 19px;
      //height: 20px;
      padding: 0 3px;
      background: linear-gradient(90deg, #ff4f42, #ff743c);
      border-radius: 2px 0 0 2px;
      font-size: 10px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #fff;
      display: block;
    }

    span:last-child {
      //height: 20px;
      border-radius: 0 2px 2px 0;
      opacity: 1;
      border-top: 1px solid #fd7100;
      border-right: 1px solid #fd7100;
      border-bottom: 1px solid #fd7100;
      padding: 0 3px;
      font-size: 10px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #fd7100;
    }
  }
}

.goods_price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 170px;
  background: #f2f8ff;
  border-radius: 4px 4px 4px 4px;
  margin: 8px 0;
  padding: 5px;
  cursor: pointer;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #666;

  .costprice,
  .profit,
  .goods_price>div {
    text-align: center;
  }
}

.names {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.findBtn {
  background-color: $color-orange;
  height: 30px;
  font-weight: 400;
  color: white;
  width: 100px;
}

.hover {
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 300%);
  width: 100%;
  height: 80px;
  line-height: 80px;
  bottom: 0;
  left: 0;
}

.heart {
  font-size: 22px;
  bottom: 10px;
  right: 10px;
}

.hezi {
  padding: 10px;
}

.hover {
  opacity: 0
}

.listClassItem:hover {
  .hover {
    opacity: 1;
    transition: all .5s;
    display: block;
  }

  .goods_btn {
    transition: all .5s;
    display: flex;
  }
}

.listClass {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-content: space-between;
}

.listClassItem .tag {
  color: #e31436;
  padding: 1px 6px;
  line-height: 16px;
  height: 16px;
  color: #fff;
  font-size: 11px;
  background: rgb(255, 153, 0);
  border-radius: 3px
}

.listClassItem .sales-info {
  color: #e46a1a;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
}

.listClassItem .price {
  color: red;
  font-size: 14px;
  text-align: left;
}

.flex-between {
  display: flex;
  align-items: center;
}

.listClassItem {
  width: 190px;
  height: 310px;
  background: #fff;
  text-align: center;
  // padding: 14px;
  margin-top: 12px;
  margin-left: 12px;

  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
}

.listClassItem:hover {
  cursor: pointer;
  border: 1px solid #e31436;
}

.card-hezi {
  width: 100%;
  height: 190px;
  overflow: hidden;
}

.listClassItem img:hover {
  transform: scale(1.1);
}

.listClassItem img {
  width: 100%;
  height: 100%;
  transition: all 0.6s ease .1s;
}

.listClassItem .name {
  display: -webkit-box;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 20px;
  overflow: hidden;
  text-align: left;
  -webkit-line-clamp: 2;
  margin-top: 4px;
}

.listClassItem:nth-child(7n+7) {
  margin-right: 0;
}

.more {
  cursor: pointer;
}

.seemore {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 50%;
  margin-left: -90px;
  cursor: pointer;
  z-index: 99;
}

.el-carousel__indicators {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.el-carousel__indicators--outside {
  bottom: 26px;
  text-align: center;
  position: static;
  transform: none;
}

.el-carousel__indicator {
  background-color: transparent;
  cursor: pointer;
}

.el-carousel__indicator--horizontal {
  display: inline-block;
  padding: 12px 4px;
}

.el-carousel__item--card,
.el-carousel__item.is-animating {
  transition: transform .4s ease-in-out;
}

.right {
  width: 190px;
  height: 694px;
  background: #fff;
  text-align: center;
  margin-left: 10px;
  position: relative;
}

.right .goods-item {
  padding: 3px 15px 10px 10px;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  align-items: center;
}


.temp-warp {
  // position: relative;
}

.el-carousel__container {
  position: relative;
  height: 300px;
}

.el-carousel--horizontal {
  overflow-x: hidden;
}

.floor {
  display: flex;
  justify-content: space-between;
}

.right h1 {
  width: 192px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  color: white;
  height: 43px;
  font-size: 18px;
  line-height: 37px;
  background: url("https://file.jingpingo.com/supply/material/18beab0d-54cc-4446-ba02-8e23b8db5ee3.png") no-repeat;
  background-size: 100% 100%;

}

.collect {
  font-size: 12px;
  color: #999;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.price {
  color: red;
  font-size: 18px;
  text-align: left;
}

.tag {
  color: #e31436;
  padding: 1px 6px;
  line-height: 16px;
  height: 16px;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
}

.sales-info {
  color: #e46a1a;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  margin: 6px 0;
}

.flex-between,
.flex-start {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  display: -webkit-box;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 20px;
  overflow: hidden;
  text-align: left;
  -webkit-line-clamp: 2;
  margin-top: 4px;
}

.floor-con .left {
  width: 190px;
  height: 592px;
  margin-top: 12px;
  position: relative;
}

.cus {
  cursor: pointer;
}

.floor-con .left .brand {
  width: 100%;
  height: 34%;
  position: absolute;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px;
  box-sizing: border-box;
  align-items: flex-start;
  padding-bottom: 4px;
}

.floor-popular .floor-title {
  width: 1000px;
  color: #000;
  height: 50px;
  padding-right: 7px;
  background-color: #fff;
  position: relative;
  text-align: left;
  border-radius: 4px;
  display: flex;
  //justify-content: space-between;
  align-items: center;
}

.floor-title h3 {
  font-size: 24px;
  margin: 0;
  font-weight: bolder;
  color: #fff;
  height: 100%;
  display: flex;
  padding-left: 26px;
  width: 166px;
  position: relative;
  clip-path: polygon(0% 0%, 0% 100%, 83% 100%, 100% 0%);
  align-items: center;
}

.child-cats {
  //width: 750px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 15px;
}

.child-cats span {
  padding: 0 8px;
}

.brand .brand-item {
  overflow: hidden;
  border: 1px solid hsla(0, 0%, 100%, .6);
  height: 30px;
  line-height: 30px;
  width: 73px;
  background: hsla(0, 0%, 100%, .1);
  text-align: center;
  cursor: pointer;
}

.floor-popular .floor-con .left .brand {
  width: 100%;
  height: 34%;
  position: absolute;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px;
  box-sizing: border-box;
  align-items: flex-start;
  padding-bottom: 4px;
}

.left img {
  width: 190px;
  height: 633px;
}

.floor {
  margin: 20px auto 0;
  width: 1200px;
}

.floor-popular .floor-con {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.left .brand {
  width: 100%;
  height: 34%;
  position: absolute;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px;
  box-sizing: border-box;
  align-items: flex-start;
  padding-bottom: 4px;
}

.left .brand {
  width: 100%;
  height: 34%;
  position: absolute;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px;
  box-sizing: border-box;
  align-items: flex-start;
  padding-bottom: 4px;
}

.img {
  width: 160px;
  height: 160px;
}

.middle {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-content: space-between;
}

.middle .goods-item {
  width: 190px;
  background: #fff;
  padding: 14px;
  height: 290px;
  box-sizing: border-box;
  margin-top: 12px;
  margin-left: 12px;
  position: relative;
}

.middle .goods-item :hover {
  cursor: pointer;
  box-shadow: 0px 1px 4px 5px rgb(235, 229, 232);
}

.heart {
  font-size: 16px;
  bottom: 10px;
  margin-right: 5px;
}
</style>
