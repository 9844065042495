/**
 * @author  XuHongli
 * @date  2022/7/11 11:30
 * @version 1.0
 * @description
 */
import storage from 'store'
import { APP_LANGUAGE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'
import { loadLanguageAsync } from '@/locales'

const app = {
  state: {
    isMobile: false,
    lang: 'zh-CN',
    _antLocale: {},
    webKeyword: {
      OPEN_SYSTEM_NAME: '',   // 系统名称
      OPEN_COM_NAME: '',     // 公司名称
      OPEN_RECORD_NO: '',   // 备案号
      OPEN_LOGO: '',       // logo
      OPEN_DOMAIN: '',    // 域名
      OPEN_PHONE: '',    // 手机号码
      OPEN_QR_CODE: '',   // 二维码内容
      OPEN_ADDRESS: '',  // 公司地址
      OPEN_EMAIL: '',  // 公司邮箱
      OPEN_SM_RESCTER: '',  // 供应商入驻链接
      OPEN_PURCHASE_HOME: '', // 采购商首页链接
      OPEN_ICON: '',     //供应链项目标签页窗口专属Logo
    }
  },
  [TOGGLE_MOBILE_TYPE]: (state, isMobile) => {
    state.isMobile = isMobile
  },
  mutations: {
    SET_WEBKEYWORD(state, data) {
      data.forEach(item => {
        state.webKeyword[item.name] = item.value
      })
    },
    [APP_LANGUAGE]: (state, lang, antd = {}) => {
      state.lang = lang
      state._antLocale = antd
      storage.set(APP_LANGUAGE, lang)
    },
  },
  actions: {
    setLang ({ commit }, lang) {
      return new Promise((resolve, reject) => {
        commit(APP_LANGUAGE, lang)
        loadLanguageAsync(lang).then(() => {
          resolve()
        }).catch((e) => {
          reject(e)
        })
      })
    }
  }
}

export default app
