/**
 * @author  XuHongli
 * @date  2022/11/29 15:26
 * @version 1.0
 * @description 用户中心布局配置
 * @supported
 *    1. 所有children里面的Url 不能重复(哪怕不是一个对象中)
 */
export const shortcutsRouteConfigDefault = [
  {
    titleName: '订单',
    url: '/user/order',
    icon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/8a279ae2-097b-438e-af7f-55cd1e2265da.png',
    selectIcon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/bee615de-0fe1-49c7-be9c-4ebe1aff8e56.png',
    children: [
      {
        titleName: '我的订单',
        url: '/user/order',
      },
      {
        titleName: '异常订单',
        url: '/user/exceptionOrder',
      },
      {
        titleName: '收货地址',
        url: '/user/address'
      }
    ]
  },
  {
    titleName: '选品库',
    url: '/user/shopLibrary',
    icon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/d506dff7-f1ee-4d2d-bdc0-030a77d05775.png',
    selectIcon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/3fb7ddaa-b371-4cc5-9486-9b0559d5c1f2.png',
    children: [
      {
        titleName: '选品库',
        url: '/user/shopLibrary'
      },
      {
        titleName: '我的收藏',
        url: '/user/favorite'
      },
      {
        titleName: '浏览历史',
        url: '/user/viewed'
      },
    ]
  },
  {
    titleName: '财务管理',
    url: '/user/general/view',
    icon: 'https://file.jingpingo.com/supply/material/afe4cc6c-9a49-44e3-92b0-5eebf0927e4e.png',
    selectIcon: 'https://file.jingpingo.com/supply/material/2a59c804-1616-4fdb-814d-ae09c83ca7ac.png',
    children: [
      {
        titleName: '资金概览',
        url: '/user/general/view'
      },
      {
        titleName: '资金流水',
        url: '/user/funding/bills'
      },
      {
        titleName: '利润明细',
        url: '/user/profit'
      },
      {
        titleName: '充值记录',
        url: '/user/recharge'
      },
      {
        titleName: '提现记录',
        url: '/user/withdrawals'
      },
      // {
      //   titleName: '结算列表',
      //   url: '/user/settlement'
      // }
    ]
  },
  {
    titleName: '发票管理',
    url: '/user/invoice/list',
    icon: 'https://file.jingpingo.com/supply/material/afe4cc6c-9a49-44e3-92b0-5eebf0927e4e.png',
    selectIcon: 'https://file.jingpingo.com/supply/material/2a59c804-1616-4fdb-814d-ae09c83ca7ac.png',
    children: [
      {
        titleName: '发票列表',
        url: '/user/invoice/list'
      },
      {
        titleName: '发票信息',
        url: '/user/invoice/info'
      }
    ]
  },
  {
    titleName: '统计',
    url: '/user/data/dealData',
    icon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/deb658b8-4f64-4c32-ae33-85de0630638c.png',
    selectIcon: 'https://jdd-shop.oss-cn-shenzhen.aliyuncs.com/purchaser/credentials/0dab967a-a1cb-451d-b780-4eead3c17084.png',
    children: [
      {
        titleName: '数据看板',
        url: '/user/data/dealData'
      },
      {
        titleName: '今日实时',
        url: '/user/data/reaData'
      }
    ]
  }
]
