/**
 * @author  XuHongli
 * @date  2023/1/5 15:47
 * @version 1.0
 * @description
 */
import request from '@/utils/axios'

/**
 * 获取登录
 */
export function getSupplyFromManufacturerList(parameter) {
  return request({
    url: '/supplyportal/supplyexternalproduct/getList',
    method: 'get',
    params: parameter
  })
}

/**
 * @description 入库商品到指定分组 厂家直供用此接口
 * @param data { { groupId: string, productIds: Array } }
 * @return {*}
 */
export function addGoodsToGroupFormManufacturer(data){
  return request({
    url: '/supplyportal/supplyexternalproduct/putin',
    method: 'post',
    data
  })
}

/** 查询商品分类*/
export function postCateTree(params){
  return request({
    url: '/supplyportal/supplyexternalproduct/cateTree',
    method: 'post',
    params
  })
}

/** 详情 */
export function getProductInfo(params){
  return request({
    url: `/supplyportal/supplyexternalproduct/getProductInfo`,
    method: 'get',
    params
  })
}
