/** with polyfills  */
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'

/**
 * WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
 * 不支持IE 请勿使用在生产环境
 */
import './mock'




/** Router */
import router from './router/routers'
import './router/index' // permission control
import FileUpload from '@/components/FileUpload'

/** Vuex */
import store from './store'


function queryURLParams(URL) {
  let url = URL.split('?')[1]
  const urlSearchParams = new URLSearchParams(url)
  localStorage.setItem('v5_Shop', [])
  console.log(urlSearchParams.entries())
  const params = Object.fromEntries(urlSearchParams.entries())
  return params
}

const urlParams = queryURLParams(window.location.href)


if (urlParams.token !== null && urlParams.token !== '' && urlParams.token !== undefined) {
  store.commit('SET_ACCESS_TOKEN', urlParams.token)
  let name, value
  let objUrlParams = {}
  let arr
  let str = location.href     //取得整个地址路径
  let num = str.indexOf('?')
  str = str.substr((num + 1))     //取得所有参数
  arr = str.split('&')   //各个参数放到数组里
  if (arr && arr.length) {
    for (let i = 0; i < arr.length; i++) {
      num = arr[i].indexOf('=')
      if (num > 0) {
        name = arr[i].substring(0, num)
        value = arr[i].substr(num + 1)
        objUrlParams[name] = value
      }
    }
  }
  if (objUrlParams.token && objUrlParams.infoProfect == 3) {
    console.log('供应商审核不通过', objUrlParams)
  } else {
    getUserInfoRz().then((res) => {
      if (res.data.confJson == null || res.data.confJson == '' || res.data.confJson == undefined) {
        store.commit('SET_IS_ENABLE_CH', 0)
        store.commit('SET_IS_OPEN_DOWN', 0)
        store.commit('SET_IS_HANDPICK_GOODS', 0)
        store.commit('SET_IS_JDGOODS', 0)
        store.commit('SET_MENG_DA', 0)
      } else {
        let configeInfo = JSON.parse(res.data.confJson)
        store.commit('SET_IS_ENABLE_CH', Number(configeInfo.isEnableCh))
        store.commit('SET_IS_OPEN_DOWN', Number(configeInfo.isOpenDown))
        store.commit('SET_IS_HANDPICK_GOODS', Number(configeInfo.isHandpickGoods))
        store.commit('SET_IS_JDGOODS', Number(configeInfo.isJDGoods))
        store.commit('SET_MENG_DA', Number(configeInfo.isMengDa))
      }
    })
    console.log('v5_Shop', urlParams.v5_Shop)
    localStorage.setItem('v5_Shop', urlParams.v5_Shop)
  }
  router.push('/')
}


// 获取接口配置信息
import { getWebData } from '@/api/Login'
getWebData().then((res) => {
  store.commit('SET_WEBKEYWORD', res.data)
  localStorage.setItem('Web_key_word', JSON.stringify(res.data))
})
Vue.prototype.$tp = function () {
  let webInfo = JSON.parse(localStorage.getItem('Web_key_word')) ? JSON.parse(localStorage.getItem('Web_key_word')) : []
  let webKeywordObj = {
    OPEN_SYSTEM_NAME: '',   // 系统名称
    OPEN_COM_NAME: '',     // 公司名称
    OPEN_RECORD_NO: '',   // 备案号
    OPEN_LOGO: '',       // logo
    OPEN_DOMAIN: '',    // 域名
    OPEN_PHONE: '',    // 手机号码
    OPEN_QR_CODE: '',  // 二维码内容
    OPEN_ADDRESS: '',  // 公司地址
    OPEN_EMAIL: '',  // 公司邮箱
    OPEN_SM_RESCTER: '',  // 供应商入驻链接
    OPEN_PURCHASE_HOME: '', // 采购商首页链接
    OPEN_LOGO_COL: '',    // 横版logo
    OPEN_SSM_RESCTER: '',  // 采购商入驻链接
    OPEN_ICON: '',     //供应链项目标签页窗口专属Logo
    IS_OPEN:'',
  }
  webInfo.forEach(item => {
    webKeywordObj[item.name] = item.value
  })
  return webKeywordObj
}
/** i18n */
import i18n from './locales'

/** Ant-Design */
import strap from '@/utils/core/strap'
import '@/utils/core/Lazy_use'
import '@/utils/filter' // global filter
import '@/utils/core/AntConfig'
import '@/utils/iconfont'
/** element ui */
import '@/utils/element'


/** Layouts */
import DefaultLayout from '@/Layouts/Default/Default.vue'
import HelpLayout from '@/Layouts/Help/HelpLayout'
import ShopIndex from '@/Layouts/ShopIndex/ShopIndex.vue'
import LoginDefault from '@/Layouts/Login/LoginDefault.vue'
import LayoutNull from '@/Layouts/Null/layout-null.vue'
import UserLayout from '@/Layouts/User/UserLayout'

Vue.component('layout-user',UserLayout)
Vue.component('layout-default', DefaultLayout)
Vue.component('layout-help', HelpLayout)
Vue.component('layout-shopindex', ShopIndex)
Vue.component('layout-login', LoginDefault)
Vue.component('layout-null', LayoutNull)

Vue.component('file-upload', FileUpload)

import FunctionComponentsList from '@/components/FunctionComponents'
import { getUserInfoRz } from '@/api/Login'

Vue.config.productionTip = false
new Vue({
  router,
  i18n,
  store,
  FunctionComponentsList,
  created: strap,
  render: h => h(App)
}).$mount('#app')
