import axios from 'axios'
import config from '@/config/defaultSettings'
import NProgress from 'nprogress'
import store from '@/store'
import { message as antMessage, notification } from 'ant-design-vue'
import { MessageBox } from 'element-ui'

import errorCode from '@/utils/errorCode'
import router from '@/router/routers'

const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : '',
  timeout: 1200000 // 请求超时时间 ms 2分钟
})

/** 超时时间 */
service.defaults.timeout = config.timeout

// 返回其他状态码
service.defaults.validateStatus = function(status) {
  return status // 默认的
}

/** 跨域请求，允许保存cookie */
service.defaults.withCredentials = true

/** NProgress Configuration */
NProgress.configure({
  showSpinner: false
})

/** HTTP request interceptors HTTP请求拦截 */
service.interceptors.request.use(config => {
  NProgress.start() // start progress bar
  const isToken = (config.headers || {}).isToken === false
  const token = store.getters.access_token
  if (token && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + token// token
  }
  // 调用加入收藏接口
  if (config.url.indexOf('supplyportal/supplyproductfoot/start') !== -1) {
    /** 在返回数据 中加入自定义数据 通过响应拦截 处理对应逻辑 */

    // 在返回数据加入触发动画数据 和 更新事件
    config.transformResponse = (data) => {
      let _data = JSON.parse(data)
      _data.updateFavoriteList = true
      _data.triggerFavoriteAnimation = true
      return _data
    }
  }
  // 通用 入库商品到指定分组 接口
  if (config.url.indexOf('/supplyportal/userpurchase/historyPution') !== -1 ||
    config.url.indexOf('/supplyportal/userpurchase') !== -1) {
    // 在返回数据 更新事件
    config.transformResponse = (data) => {
      let _data = JSON.parse(data)
      _data.updateFavoriteList = true
      return _data
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})


/** HTTP response interceptors HTTP响应拦截 */
service.interceptors.response.use(res => {
  NProgress.done()
  const status = Number(res.status) || 200
  const message = res.data.msg || errorCode[status] || errorCode['default']
  // console.log('phone', store.getters.webKeyword.OPEN_PHONE)
  if (status === 401) {
    const statusMessage = message.split(',')[1]
    if ('2' === statusMessage) {
      MessageBox.confirm('此手机号码已申请供应商账号前往供应商账号登录页面进行登录。','提示',{
        confirmButtonText: '立即前往',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.open('http://sm.wanjingsc.com/#/login')
      }).catch(() => {})
    } else if('1' === statusMessage){
      MessageBox.confirm(`您提交的资料正在审核中，我们会在1~3个工作日完成审核。如有疑问请联系：${store.getters.webKeyword.OPEN_PHONE}`,'提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

      }).catch(() => {

      })
    } else {
      antMessage.error('长时间未操作，请重新登录')
      store.dispatch('FedLogOut').then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 500)
      })
    }

    return
  }

  if (status !== 200 || res.data.code === 1) {
    if ('infoNotOk' === res.data.msg) return res.data   // 注：采购商注册手机号关闭页面重新注册流程使用该字段做判断条件
    if ('手机号已注册' === res.data.msg) return res.data.msg
    notification.error({
      description: message,
      message: message
    })
    return Promise.reject(new Error(message))
  }

  // 触发更新收藏列表
  if (res.data.updateFavoriteList === true) {
    store.commit('SET_UPDATE_FAVORITE_LIST_COUNT')
  }
  // 如果数据中含有 "triggerFavoriteAnimation" 触发动画事件
  if (res.data.triggerFavoriteAnimation === true && res.data.data === 1) {
    console.log(res.data, '收藏成功')
    store.commit('SET_TRIGGER_FAVORITE_ANIMATION', true)
  }


  return res.data
}, error => {
  NProgress.done()
  return Promise.reject(new Error(error))
})

export default service
