/**
 * @author  XuHongli
 * @date  2022/8/24 14:33
 * @version 1.0
 * @description Login Api
 */

import request from '@/utils/axios'

const scope = 'server'

export function getWebData() {
  return request({
    url: '/supplyportal/userpurchase/listByOpen',
    method: 'get',
  })
}
/**
 * 通过用户名登录
 * @param username
 * @param password
 * @param code
 * @param randomStr
 * @return {*}
 */
export const loginByUsername = (username, password, code, randomStr) => {
  const grant_type = 'password'

  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'Authorization': 'Basic YWRtaW46YWRtaW4=',
      'ClientId': 'Basic cG9ydGFsOnBvcnRhbA==',
      'Accept': 'application/JSON;charset=UTF-8'
    },
    method: 'post',
    params: { username, password, randomStr, code, grant_type, scope }
  })
}

/**
 * 根据手机号登录
 * @param phone
 * @param code
 * @return {*}
 */
export const loginByPhone = (phone, code) => {
  const grant_type = 'sms_login'
  return request({
    url: '/auth/phone/token/sms',
    headers: {
      'Authorization': 'Basic YWRtaW46YWRtaW4=',
      'ClientId': 'Basic cG9ydGFsOnBvcnRhbA=='
    },
    method: 'post',
    params: { phone: phone, code: code, grant_type }
  })
}

// 校验用户是否审核通过
export function verifyUser() {
  return request({
    url: `supplyportal/purchaserapply/verifyUser`,
    method: 'get'
  })
}
// 供应商校验用户是否审核通过
export function verifySupplier() {
  return request({
    url: `/supplyadmin/manufacturerapply/verifyUser`,
    method: 'get'
  })
}

// 采购商营业执照识别接口
export function getPirchBusiness(params) {
  return request({
    url: `/supplyportal/purchaserapply/business`,
    method: 'get',
    params
  })
}

// 供应商营业执照识别接口
export function getManufacBusiness(params) {
  return request({
    url: `/supplyadmin/manufacturerapply/business`,
    method: 'get',
    params
  })
}

/**
 * 获取用户信息
 * @return {AxiosPromise}
 */
export const getUserInfo = () => {
  return request({
    url: '/upms/user/info',
    method: 'get'
  })
}

/**
 * 编辑用户信息
 */
export const editBaseUser = (data) => {
  return request({
    url: '/upms/user/editBaseUser',
    method: 'post',
    data
  })
}

/**
 * 忘记密码发送验证码
 * @param phone {string | number}
 * @return {*}
 */
export const forgetPasswordSendCode = (phone) => {
  return request({
    url: '/upms/user/editPasswordVerify/' + phone,
    method: 'get'
  })
}

export function forgetPasswordEdit(params) {
  return request({
    url: '/upms/user/forgetEditPassword',
    method: 'PUT',
    params
  })
}

/**
 * 修改密码
 * @param obj
 * @return {*}
 */
export function editPassword(obj) {
  return request({
    url: '/upms/user/edit',
    method: 'put',
    data: obj
  })
}

/**
 * 修改用户信息
 */
export function editUserData(data) {
  return request({
    url: '/upms/user/edit',
    method: 'put',
    data
  })
}

/**
 * 分送验证码 根据参数中的type区分作用
 */
export function sendSmsCode(data) {
  return request({
    url: '/upms/phone/code',
    method: 'put',
    params: data
  })
}

/**
 *  采购入住
 */

export function submitUserRegisterFrom(data) {
  return request({
    headers: {
      isToken: true
    },
    url: '/supplyportal/purchaserapply',
    method: 'post',
    data
  })
}

/**
 * 厂商入住
 */
export function submitManufacturerRegister(data) {
  return request({
    headers: {
      isToken: false
    },
    url: '/supplyadmin/manufacturerapply/addOrUpdateManufacturerApply',
    method: 'put',
    data
  })
}

/**
 * 个人中心修改手机号————编辑号码验证[二维码]
 */
export function getEditPhone(phone) {
  return request({
    url: '/upms/user/editPhoneVerify/' + phone,
    method: 'get'
  })
}

/**
 * 个人中心修改手机号————编辑号码[提交表单数据]
 */
export function getEditUserPhone(parameter) {
  return request({
    url: '/supplyportal/purchaserinfo/editPhone',
    method: 'get',
    params: parameter
  })
}

/**
 * 采购入驻申请————注册发送验证码
 */
export function registerCode(phone) {
  return request({
    url: '/supplyportal/purchaserapply/sendRegistCode/' + phone,
    method: 'get'
  })
}

/**
 * 采购入驻申请————采购入驻前注册账号
 */
export function settledAccount(data) {
  return request({
    url: '/supplyportal/purchaserapply/register',
    method: 'post',
    data
  })
}

/**
 * 供应商入驻申请————供应商入驻前注册账号
 */
export function settledSupplier(data) {
  return request({
    url: '/supplyadmin/manufacturerapply/register',
    method: 'post',
    data
  })
}

/**
 * 供应商入驻申请————注册发送验证码
 */
export function supplierCode(phone) {
  return request({
    url: '/supplyadmin/manufacturerapply/sendRegistCode/' + phone,
    method: 'get'
  })
}

// 获取企业认证信息
export function getUserInfoRz() {
  return request({
    url: '/supplyportal/purchaserinfo/getInfo',
    method: 'get'
  })
}


//
// 手机号码验证是否注册


export function checkPhone(data) {
  return request({
    url: '/supplyportal/purchaserapply/checkPhone',
    method: 'get',
    params:data
  })
}
