/**
 * @author  XuHongli
 * @date  2022/9/5 18:16
 * @version 1.0
 * @description 查询商品
 */
import store from '@/store'
import request from '@/utils/axios'
import { message, notification } from 'ant-design-vue'

// 首页查左侧分类接口
export function cateList (id){
  return request({
    url:'/supplyportal/appInteriorBrand/cateList?cateId='+id,
    method:'get'
  })
}
// 素材下载

export function downloadZipFile (parameter) {
  return request({
    url: '/supplyportal/supplyproduct/page',
    method: 'get',
    params: parameter
  })
}

// 首页分类接口

export function cateIndex (parameter) {
  return request({
    url: '/supplyportal/supplyCateIndex/cateIndex',
    method: 'get',
    params: parameter
  })
}

// 关键字搜索

export function supplyKeyword() {
   return request({
    url: '/supplyportal/supplyKeyword/list',
    method: 'get',
  })
}
export function getProductPage (parameter) {
  return request({
    url: '/supplyportal/supplyproduct/page',
    method: 'get',
    params: parameter
  })
}

export function getProductSpec(id) {
  return request({
    url: '/supplyportal/supplyproductspec/tree/' + id,
    method: 'get',
  })
}

export function getRandomProduct (data) {
  return request({
    url: '/supplyportal/supplyproduct/getRandomProduct',
    method: 'get',
    params:data
  })
}

export function indexPage (data) {
  return request({
    url: '/supplyportal/supplygoodscategory/indexPage',
    method: 'get',
    params:data
  })
}


/**
 * @description 获取相关数据
 * @param id
 * @return {*}
 */
export function getCateProducts(id) {
  return request({
    url: '/supplyportal/supplyproduct/queryCateProducts/' + id,
    method: 'get'
  })
}

/**
 * 获取商品详情
 * @return {*}
 * @param id
 */
export function getProductObj (id) {
  return request({
    url: '/supplyportal/supplyproduct/' + id,
    method: 'get',
  })
}

/**
 * 收藏或者取消收藏
 */
export function updateFavoriteProduct(id) {
  return request({
    url: '/supplyportal/supplyproductfoot/start/' + id,
    method: 'put',
  })
}

// 一键加入商品库
export function moveAdd(data) {
  return request({
    url: '/supplyportal/userpurchase/move',
    method: 'post',
    data:data
  })
}

/**
 * 公用收藏方法
 * @param id
 * @return {Promise<unknown>}
 */
export function favoriteProduct(id) {
  const userInfo = store.getters.userInfo

  if (Object.keys(userInfo).length > 0) {
    return new Promise(resolve => {
      updateFavoriteProduct(id).then((res) => {
        message.success(res.msg)
        resolve(res.msg)
      })
    })
  } else {
    notification.error({
      message: '您还未登录账户, 请登录账号'
    })
    return new Promise((resolve, reject) => {
      reject(new Error('账号未登录, 不能进行收藏'))
    })
  }
}

//  货源订单管理————查询运费价格
export function freightProduct(data) {
  return request({
    url: '/supplyportal/supplyorderinfo/queryFreightPrice',
    method: 'post',
    data
  })
}

//  获取运费详情
export function templateById(id) {
  return request({
    url: '/supplyportal/supplyproduct/getTemplateById/' + id,
    method: 'get',
  })
}

//  货源订单管理————查询城市树
export function inquireCityTree() {
  return request({
    url: '/supplyportal/region/citys',
    method: 'get'
  })
}

// 得到所有正常的国家产地
export function allLocality() {
  return request({
    url: '/supplyportal/appCountry/getAllList',
    method: 'get'
  })
}

// 得到所有正常的供应商品牌
export function allBrandlity() {
  return request({
    url: '/supplyportal/appInteriorBrand/getAllList',
    method: 'get'
  })
}

export function getFindSimilarity(params) {
  return request({
    url: '/supplyportal/supplyproduct/findSimilarity',
    method: 'get',
    params
  })
}
