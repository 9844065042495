/**
 * @author  XuHongli
 * @date  2022/7/11 11:27
 * @version 1.0
 * @description
 */

const getters = {
  isMobile: state => state.app.isMobile,
  lang: state => state.app.lang,
  userInfo: state => state.user.userInfo,
  access_token: state => state.user.access_token,
  infoProfect: state => state.user.infoProfect,
  webKeyword:state => state.app.webKeyword,
  // 是否开启厂家直供功能
  isEnableCh: state => state.user.isEnableCh,
  // 是否开启 精选商品
  isHandpickGoods: state => state.user.isHandpickGoods,
  // 是否开启 京东直选
  isJDGoods: state => state.user.isJDGoods,
  // 是否开启 盟大
  isMengDa: state => state.user.isMengDa,
  // 动画
  triggerFavoriteAnimation: state => state.animation.triggerFavoriteAnimation,
  updateFavoriteListCount: state => state.animation.updateFavoriteListCount,
}

export default getters
