<template>
  <div class="login_header widths">
    <img :src="$tp().OPEN_LOGO_COL" alt="" class="head-logo" @click="$router.push('/')">
    <div class="line"></div>
    <h3 class="title">{{ register ? '登录' : '账户注册' }}</h3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * @author  XuHongli
 * @date  2023/1/30 14:10
 * @version 1.0
 * @description
 */
export default {
  name: 'LoginOrRegisterHeader',
  computed: {
    ...mapGetters(['webKeyword'])
  },
  data() {
    return {
      register: true
    }
  },
  created() {
    this.register = this.$route.path.indexOf('login') !== -1
  }

}
</script>

<style lang="scss" scoped>
.line {
  line-height: 29px;
  height: 29px;
  width: 19px;
  border-left: #cccccc 1px solid;
}
.widths {
  width: 1200px;
  margin: 0 auto;
}
.login_header{
  //background-color: #fff;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  .title{
    color: #666666;
    font-size: 24px;
    margin: 0;
    font-weight: 400;
  }
}
.head-logo{
  height: auto;
  width: 150px;
  cursor: pointer;
}
</style>
