/**
 * @author  XuHongli
 * @date  2022/8/23 17:16
 * @version 1.0
 * @description 错误信息代码
 */
export default {
  '000': '操作太频繁，请勿重复请求',
  '401': '登录过期',
  '403': '当前操作没有权限',
  '404': '资源不存在',
  '417': '未绑定登录账号，请使用密码登录后绑定',
  '426': '用户名不存在或密码错误',
  '428': '验证码错误,请重新输入',
  '429': '请求过频繁',
  '503': '服务不可用，请确保相应服务已启动成功',
  'default': '系统未知错误,请反馈给管理员'
}
