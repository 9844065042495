<template>
  <div style="position: relative">
    <carousel
      :loop="loop"
      :autoWidth="false"
      :items="4"
      :dots="true"
      :margin="10"
      :nav="false"
      :mouseDrag="true"
    >
      <slot></slot>
      <template slot="prev">
        <div :class="['prev',button?'prev2':'']" ref="prev">
          <a-icon type="left"></a-icon>
        </div>
      </template>
      <template slot="next">
        <div :class="['next',button?'next2':'']">
          <a-icon type="right"></a-icon>
        </div>
      </template>

    </carousel>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/7/25 18:34
 * @version 1.0
 * @description
 */
import carousel from 'vue-owl-carousel'

export default {
  name: 'OwlCarousel',
  components: { carousel },
  props: {
    loop: {
      type: Boolean,
      default: true
    },
    button: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.prev.parentElement.style.display = 'block'
    })
  }
}
</script>

<style scoped>
.prev, .next {
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 28px;
  color: #fff;
  background-color: #2E2828;
  opacity: 0.65;
}
.prev2, .next2 {
  top: 50%;
  transform: translateY(-50%);
  background-color: unset;
  color: #707070;
}

.prev {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  left: 0;
}
.next {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  right: 0;
}
@media (min-width: 1541px) {
  .next {
    right: 10px;
  }
}

</style>
