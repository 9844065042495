/**
 * @author  XuHongli
 * @date  2022/8/23 16:16
 * @version 1.0
 * @description 用户信息
 */
import { encryption } from '@/utils/utils'
import { securityKey } from '@/config/env'
import storage from 'store'
import { getToken, tokenKey, setToken } from '@/utils/cookie'
import { getUserInfo, loginByPhone, loginByUsername } from '@/api/Login'

const user = {
  state: {
    userInfo: {},
    access_token: storage.get(tokenKey) || getToken() || '',
    refresh_token: storage.get('refresh_token') || '',
    expires_in: storage.get('expires_in') || '',
    infoProfect: 0,
    isEnableCh: storage.get('isEnableCh') || null, // 是否开启 厂货直供功能
    isOpenDown: storage.get('isOpenDown') || null, // 是否开启 一键下载资源包
    isHandpickGoods: storage.get('isHandpickGoods') || null, // 是否开启 精选商品
    isJDGoods: storage.get('isJDGoods') || null, // 是否开启 京东直选
    isMengDa: storage.get('isMengDa') || null, // 是否开启 盟大
  },
  mutations: {
    SET_MENG_DA: (state,val) => {
      storage.set('isMengDa', val)
      state.isMengDa = val
    },
    SET_IS_JDGOODS: (state,val) => {
      storage.set('isJDGoods', val)
      state.isJDGoods = val
    },
    SET_IS_HANDPICK_GOODS: (state,val) => {
      storage.set('isHandpickGoods', val)
      state.isHandpickGoods = val
    },
    SET_IS_OPEN_DOWN: (state,val) => {
      storage.set('isOpenDown', val)
      state.isOpenDown = val
    },
    SET_INFO_PROFECT: (state, infoProfect) => { state.infoProfect = infoProfect },
    SET_ACCESS_TOKEN: (state, accessToken) => {
      state.access_token = accessToken
      storage.set(tokenKey, state.access_token)
      setToken(state.access_token)
    },
    SET_IS_ENABLE_CH: (state,val) => {
      storage.set('isEnableCh', val)
      state.isEnableCh = val
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      storage.set('refresh_token', state.refresh_token)
      state.refresh_token = rfToken
    },
    SET_EXPIRES_IN: (state, expires_in) => {
      state.expires_in = expires_in
      storage.set(expires_in, state.expires_in)
      storage.set('expires_in', state.expires_in)
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
  },
  actions: {
    /**
     * 通过用户名进行加密登录
     * @param commit
     * @param userInfo
     * @return {Promise<unknown>}
     * @constructor
     */
    LoginByUsername({ commit }, userInfo) {
      const user = encryption({
        data: userInfo,
        key: securityKey,
        param: ['password']
      })
      console.log(user)
      return new Promise((resolve, reject) => {
        loginByUsername(user.username, user.password, user.code, user.randomStr).then(response => {
          const data = response
          setToken(data.access_token)
          commit('SET_ACCESS_TOKEN', data.access_token)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          commit('SET_EXPIRES_IN', data.expires_in)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    /**
     * 通过收i及验证码登录
     * @param commit
     * @param userInfo
     * @return {Promise<unknown>}
     * @constructor
     */
    LoginByPhone({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByPhone(userInfo.phone, userInfo.code).then(response => {
          const data = response
          commit('SET_ACCESS_TOKEN', data.access_token)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          commit('SET_EXPIRES_IN', data.expires_in)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    /**
     * 获取用户信息
     * @param commit
     * @return {Promise<unknown>}
     * @constructor
     */
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then((res) => {
          const data = res.data || {}
          commit('SET_USER_INFO', data.sysUser)
          commit('SET_INFO_PROFECT', data.sysUser.infoProfect)
          resolve(data)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    /** 注销session  */
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_USER_INFO', {})
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        commit('SET_IS_ENABLE_CH', null)
        commit('SET_IS_OPEN_DOWN', null)
        commit('SET_IS_HANDPICK_GOODS', null)
        commit('SET_IS_JDGOODS', null)
        commit('SET_MENG_DA', null)
        resolve()
      })
    },
  }
}

export default user
