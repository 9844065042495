<template>
  <a-modal :visible="visible" cancelText="取消" centered okText="确认" width="618px" @cancel="cancel" @ok="ok">
    <h6>
      将已选取的 <span class="text-danger">{{ ids.length }}</span> 件商品加入商品库
    </h6>
    <a-spin :spinning="loading" tip="Loading...">
      <div class="flex-row flex-col-center">
        <template v-if="!showAddGroup">
          <a-button size="small" type="info" @click="handleAddGroupCard">新建普通分组</a-button>
        </template>
        <template v-else>
          <a-input
            v-model.trim="addGroupName"
            :placeholder="addGroupPlaceholder"
            class="mr-5"
            size="small"
            style="width: 200px"
          ></a-input>
          <a-button class="mr-5" size="small" type="primary" @click="addGroup">确定</a-button>
          <a-button size="small" type="info" @click="CancelAddGroup">取消</a-button>
        </template>
        <template v-if="prodctCodes.length">
          <a-select v-model="selectCode" placeholder="请选择产品" size="small" style="width: 120px; margin-left: 15px">
            <a-select-option v-for="(item, index) in prodctCodes" :key="index" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </template>
      </div>
      <div class="GroupList mt-10">
        <a-card
          v-for="(item, index) in GroupList"
          :key="index"
          :class="['groupCard', 'mr-10', 'mb-10', { active: selectGroupsIndex === index }]"
          style="width: 255px"
          @click="selectGroupsIndex = index"
        >
          <p class="groupCard-title">{{ item.name }}</p>
          <p class="groupCard-count">{{ `商品数量: ${item.count}` }}</p>
        </a-card>
        <strong v-if="GroupList.length === 0">暂无分组,请先创建分组</strong>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { jdgoodsPutin } from '@/api/JdSelection'
import { selfPutin } from '@/api/HandpickGoods'
import {
  addGoodsToGroup,
  addGoodsToGroupFormViewedHistory,
  addGroup,
  getGroupList,
  addMdPutin,
} from '@/api/SelectGoods'
import moment from 'moment'
import { addGoodsToGroupFormManufacturer } from '@/api/SupplyFromManufacturer'
/**
 * @author  XuHongli
 * @date  2022/12/2 10:43
 * @version 1.0
 * @description 将商品添加进 商品库 分组弹窗
 */
export default {
  name: 'AddGroupModal',
  props: {
    ids: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Function, //传入移除节点方法,这里是createApp中的方法
      default: null,
    },
    handleOk: {
      type: Function, //成功回调
      default: null,
    },
    // 从历史记录页面入库需要传入
    isHistory: {
      type: Boolean,
      default: false,
    },
    // 从厂家直供页面入库需要传入
    isManufacturer: {
      type: Boolean,
      default: false,
    },
    // 从精选商品页面入库需要传入
    isHandpickGoods: {
      type: Boolean,
      default: false,
    },
    // 从京东直选页面入库需要传入
    isJdSelection: {
      type: Boolean,
      default: false,
    },
    //从盟大页面入库需传入
    md: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 函数式组件控制 显示隐藏
      pageVisible: false,
      loading: false,
      //  分组列表
      GroupList: [],
      // 显示添加分组
      showAddGroup: false,
      // 添加分组的名字
      addGroupName: '',
      // 默认的placeholder
      addGroupPlaceholder: '',
      // 选中的分组
      selectGroupsIndex: '',
      // V5商城-编码
      prodctCodes: [],
      selectCode: '',
    }
  },
  created() {
    this.init()
    this.initV5Shop()
  },
  methods: {
    initV5Shop() {
      if (!localStorage.getItem('v5_Shop')) return
      let v5_Shop = localStorage.getItem('v5_Shop') ? JSON.parse(localStorage.getItem('v5_Shop')) : []
      for (let item of v5_Shop) {
        this.prodctCodes.push({
          label: item == '88000003' ? '商城' : '盲盒商城',
          value: item,
        })
      }
      this.selectCode = this.prodctCodes[0].value
    },
    init() {
      console.log(this, '函数式组件测试')
      if (this.isHistory && this.isManufacturer && this.isHandpickGoods && this.isJdSelection && this.md) {
        new Error('isHistory & isManufacturer & isHandpickGoods & isJdSelection 不可同时为true')
      }
      this.loading = true
      getGroupList().then((res) => {
        this.GroupList = res.data
        this.loading = false
      })
    },
    // 添加组
    addGroup() {
      // TODO 添加校验逻辑
      this.loading = true
      if (this.addGroupName === '' || this.addGroupName === null || this.addGroupPlaceholder === undefined) {
        this.addGroupName = this.addGroupPlaceholder
      }
      addGroup(this.addGroupName).then((res) => {
        this.showAddGroup = false
        this.addGroupName = ''
        this.init()
      })
    },
    // 点击 新增分组
    handleAddGroupCard() {
      this.addGroupPlaceholder = `创建于 ${moment().format('YYYY-MM-DD HH:mm:ss')}`
      this.showAddGroup = true
    },
    // 新增分组的时候点击取消
    CancelAddGroup() {
      this.showAddGroup = false
      this.addGroupName = ''
    },
    // 点击取消
    cancel() {
      typeof this.handleOk === 'function' && this.handleOk('cancel')
      this.pageVisible = false
      this.remove()
    },
    // 点击确定
    ok() {
      if (this.selectGroupsIndex === '') {
        this.$message.error('您还没有选择分组')
      } else {
        this.loading = true
        let queryData = {
          groupId: this.GroupList[this.selectGroupsIndex].id,
          productIds: this.ids,
        }
        if (this.isHistory) {
          addGoodsToGroupFormViewedHistory(queryData, this.selectCode).then((res) => {
            this.okCallBack(res)
          })
        } else if (this.isManufacturer) {
          addGoodsToGroupFormManufacturer(queryData).then((res) => {
            this.okCallBack(res)
          })
        } else if (this.isHandpickGoods) {
          selfPutin(queryData).then((res) => {
            this.okCallBack(res)
          })
        } else if (this.isJdSelection) {
          jdgoodsPutin(queryData).then((res) => {
            this.okCallBack(res)
          })
        } else if (this.md) {
          // 盟大商品入库至选品库
          addMdPutin(queryData).then((res) => {
            this.okCallBack(res)
          })
        } else {
          addGoodsToGroup(queryData, this.selectCode).then((res) => {
            this.okCallBack(res)
          })
        }
      }
    },
    // 成功之后回调函数
    okCallBack(res) {
      this.loading = false
      this.$message.success(res.msg)
      this.$emit('ok', true)
      /* 考虑如果接口失败不许要触发函数 */
      // if ('已入库' === res.msg) typeof this.handleOk === 'function' && this.handleOk('ok')
      if (0 === res.code) typeof this.handleOk === 'function' && this.handleOk('ok')
      this.pageVisible = false
      this.remove()
    },
  },
}
</script>

<style lang="scss" scoped>
.groupCard {
  .ant-card-body {
    padding: 10px;
  }

  &:hover:not(.active) {
    background-color: #ededf0;

    .groupCard-title {
      color: $color-orange;
    }
  }
}

.groupCard.active {
  background-color: $color-orange;
  color: white;
}

.GroupList {
  background-color: #fafafa;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  max-height: 420px;
  overflow-y: scroll;
}
</style>
