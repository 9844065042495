<template>
  <div style="padding-bottom: 20px;background: #f4f4f4;">
    <!--  轮播图   -->
    <div class="main-content dis" style="height: 420px;position: relative;margin-bottom: 10px;margin-top: 8px;">
      <div class="content " style="width:220px;">
        <div class="roqi">
          <a-dropdown-button>
            <b style="font-size: 15px;color: red;">频道选择</b>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item :key="1">
                <a-icon type="reddit" />精选商品
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item :key="3">
                <a-icon type="file-protect" />京东品质保证
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item :key="2">
                <a-icon type="fire" />1688厂货直供
              </a-menu-item>
            </a-menu>
            <span slot="icon">{{ getName() }}<a-icon type="caret-down" /></span>
          </a-dropdown-button>
        </div>
        <ul class="category" @mouseleave="hoverNavMenu = false" @mousemove="hoverNavMenu = true">
          <li v-for="(item, index) in CategoryTree" :key="index" class="flex-row" style="padding: 5px 0;">
            <div class="disc-flex">
              <div v-for="(it, index1) in item" :key="index1" class="cateFirst" @click="goSearch(it.id)">
                <img v-if="it.picUrl" :src="it.picUrl" style="width:18px;height:18px;margin-right:5px" />
                <span class="wbyc">{{ it.name }}</span>
              </div>
            </div>
            <div class="sub_category" style="z-index:1200">
              <div v-for="(quer, index2) in item" :key="index2">
                <ul style="width: 100%">
                  <li v-for="(children, i) in quer.childrens" :key="`${index}-${i}`" style=" width: 100%;">
                    <template>
                      <div style="margin:5px 5px 5px 0;">
                        <div style="font-weight: 700;cursor: pointer;color: #ff2900;">{{ children.name }}</div>
                      </div>
                      <div style="display: flex; flex-wrap: wrap;">
                        <div v-for="childrens in children.childrens" :key="childrens.id" class="threeCate"
                          @click="goSearch(quer.id, children.id, childrens.id)">
                          {{ childrens.name }}
                        </div>
                      </div>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="cont" style=" width: 600px;">
        <a-carousel v-if="bannerList.length > 0" autoplay effect="fade">
          <div v-for="item in bannerList" :key="item.id">
            <div style="cursor: pointer" @click="fn(item)">
              <img :src="item.imgUrl" style="height: 410px;width: 100%;z-index:10;" />
            </div>
          </div>
        </a-carousel>
      </div>
      <div class="right">
        <div class="right-top">
          <div class="right-flex">
            <div>
              <el-avatar :size="54" :src="userData.imgUrl ? userData.imgUrl : circleUrl"></el-avatar>
            </div>
            <div style="margin-left:20px">
              <p v-if="userInfo.infoProfect === 3 || Object.keys(userInfo).length === 0">
                {{ getDay() + '欢迎来到' + webKeyword.OPEN_SYSTEM_NAME + '!' }}
              </p>
              <p v-else>
                <span>
                  {{ getDay() }} {{ userData.businessName == null ? webKeyword.OPEN_SYSTEM_NAME + '!'
                    : userData.businessName }}
                </span>
              </p>

              <span class="right-top-cai" style="cursor: default">我的采购需求</span>
            </div>
          </div>
          <div style="margin-top:20px">
            <el-button v-if="Object.keys(userInfo).length === 0" class="right-leftbtn" size="mini"
              @click="onJumpLogin()">登录</el-button>
            <el-button v-else class="right-leftbtn" size="mini" @click="userCenter">
              个人中心
            </el-button>
            <el-button v-if="Object.keys(userInfo).length === 0 || userInfo.infoProfect === 3" class="right-rightbtn"
              style="background: #ffffff;margin-left: 10px;"
              @click="onJump()">免费注册</el-button>
            <el-button v-else class="right-rightbtn" size="mini" @click="logout">退出</el-button>
          </div>
        </div>
        <div>
          <img alt="" class="right-img" src="/img/bgs.png">
        </div>
        <div class="right-flex right-bottom">
          <div class="right-center" style="width:25%" @click="$router.push({ path: '/user/order?status=' + 0 })">
            <p>{{ OrderStatus.waitPay || 0 }}</p>
            <span>待付款</span>
          </div>
          <div class="right-center" style="width:25%" @click="$router.push({ path: '/user/order?status=' + 1 })">
            <p>{{ OrderStatus.waitDeliveredCount || 0 }}</p>
            <span>待发货</span>
          </div>
          <div class="right-center" style="width:25%" @click="$router.push({ path: '/user/order?status=' + 2 })">
            <p>{{ OrderStatus.deliveredCount || 0 }}</p>
            <span>待收货</span>
          </div>
          <div class="right-center" style="width:25%" @click="$router.push({ path: '/user/order?status=' + 3 })">
            <p>{{ OrderStatus.tackCount || 0 }}</p>
            <span>待确认</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main-content">
      <!-- 每日必抢 -->
      <RobEverdayVue :timeList="timeList" @handleAddGoodsg="getPage1" />
      <!-- 新品推荐tab -->
      <tabListVue ref="tabList" :cutList="cutList" @cut="getRaroduct" @handleAddGoodsgs="getRaroduct" />
      <!-- 每日推荐图片 -->
      <picturesVue :picList="precinctData"></picturesVue>
      <!-- 5个列表 -->
      <categoryListingsVue :cateUplist="cateUplist"></categoryListingsVue>
    </div>
  </div>
</template>

<script>

import { getProductPage, indexPage, getRandomProduct, cateIndex, getProductObj } from '@/api/Product'
import { getPrecinctList } from '@/api/Precinct'
import { getUserInfoRz } from '@/api/Login'
import { supplyBanner} from '@/api/Category'
import { getUserOrderStatus } from '@/api/Order'

import RobEverdayVue from './RobEverday.vue'
import picturesVue from './pictures.vue'
import tabListVue from './tabList.vue'
import categoryListingsVue from './categoryListings.vue'
import Ellipsis from '@/components/Ellipsis/Ellipsis'   // 该组件实现文本过多显示省略号
import nationalVue from './national.vue'                // 国家组件
import cloudwArehouseVue from './cloudwArehouse.vue'   // 优选云仓组件

import { mapGetters } from 'vuex'
import store from "@/store";

export default {
  name: 'Home',
  components: {
    RobEverdayVue,
    tabListVue,
    picturesVue,
    categoryListingsVue
  },
  data() {
    return {
      timeList: [],  // 每日必抢数据
      picList: [    // 每日推荐图片文案数据
        {
          id: 1,
          title: '1-3折专区',
          imgList: [
            'https://file.jingpingo.com/supply/material/c64b9311-b63b-4e73-b22b-1b2715beb5de.jpg',
            'https://file.jingpingo.com/supply/material/c1f7fee3-d905-4891-b1fc-970e602f2b7a.png'
          ]
        },
        {
          id: 2,
          title: '社团选品',
          imgList: [
            'https://file.jingpingo.com/supply/material/ffdfd025-75a2-43e2-bf85-5da9ac83ac62.jpg',
            'https://file.jingpingo.com/supply/material/4d02fdd4-bbd4-4137-b912-831ec5c64f37.jpg'
          ]
        },
        {
          id: 3,
          title: '爆品伙拼',
          imgList: [
            'https://file.jingpingo.com/supply/material/e1ba904e-d6b7-4deb-a86c-4c596ae6f167.jpg',
            'https://file.jingpingo.com/supply/material/ca77c725-73a3-4852-998d-2863cee91709.jpg'
          ]
        },
        {
          id: 4,
          title: '引流专区',
          imgList: [
            'https://file.jingpingo.com/supply/material/d6bec682-a77c-4ee3-a733-1aceb4276080.png',
            'https://file.jingpingo.com/supply/material/3c2ed14a-4721-43c5-931f-a0fa68cc71b6.png'
          ]
        }
      ],
      type:1,  // 首页类目展示类型区分
      CategoryTree: [],  // 首页类目数据
      cutList: [],    // 新品推荐tab数据
      bannerList: [], //轮播图
      hoverNavMenu: false,  // 悬浮事件
      circleUrl: 'http://minio.joolun.com/joolun/1/material/32f19366-3c43-4002-9a82-c984a2d20bbf.png',  // 头像
      OrderStatus: {   //  订单价格显示数据
        deliveredCount: 0,
        tackCount: 0,
        waitDeliveredCount: 0,
        waitPay: 0,
      },
      userData: {},  // 账号信息数据
      cateUplist: [],  // 5个类目列表
      precinctData: []   // 专区-每日推荐图片
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect', 'webKeyword'])
  },
  created() {
    this.getInfo()  // 获取个人信息
    this.getPage1()  // 每日必抢
    this.getHome()   // 5个类目列表
    this.randomProductFn()  // 新品推荐tab
    this.getCat()  // 推荐分类
    this.precinctListFn()  // 专区列表
    this.getsupplyBanner()  // 轮播图
  },
  mounted() {
    if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
      getUserOrderStatus().then((res) => {
        this.OrderStatus = res.data
      })
    }
    if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 3) {
      this.$store.dispatch('FedLogOut').then(() => {
        if (this.$tp().IS_OPEN == 1) {
          let PAGE_URL = window.origin + '/user/login'
          window.open(PAGE_URL, '_blank')
        } else {
          this.$router.push({ path: '/login' })
        }
      })
    }
  },
  methods: {
    precinctListFn() {
      getPrecinctList().then(res => {
        this.precinctData = res.data
      })
    },
    getCat() {
      indexPage({type: this.type}).then(res => {
        this.CategoryTree = res.data
      })
    },
    randomProductFn () {
      getRandomProduct({ news: 1 }).then(res => {
        this.cutList = res.data.records
      })
    },
    getInfo() {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        getUserInfoRz().then((res) => {
          if (res.ok) {
            this.userData = res.data
          }
          if (res.data.confJson == null || res.data.confJson == '' || res.data.confJson == undefined) {
            this.$store.commit('SET_IS_ENABLE_CH', 0)
            this.$store.commit('SET_IS_OPEN_DOWN', 0)
            this.$store.commit('SET_IS_HANDPICK_GOODS', 0)
            this.$store.commit('SET_IS_JDGOODS', 0)
            this.$store.commit('SET_MENG_DA', 0)
          } else {
            let configeInfo = JSON.parse(res.data.confJson)
            this.$store.commit('SET_IS_ENABLE_CH', Number(configeInfo.isEnableCh))
            this.$store.commit('SET_IS_OPEN_DOWN', Number(configeInfo.isOpenDown))
            this.$store.commit('SET_IS_HANDPICK_GOODS', Number(configeInfo.isHandpickGoods))
            this.$store.commit('SET_IS_JDGOODS', Number(configeInfo.isJDGoods))
            this.$store.commit('SET_MENG_DA', Number(configeInfo.isMengDa))
          }
        })
      }
    },
    // 频道
    handleMenuClick(e) {
      this.type = e.key
      indexPage({type: this.type}).then(res => {
        this.CategoryTree = res.data
      })
    },
    getName() {
      if (1 === this.type) {
        return '精选商品'
      } else if (2 == this.type) {
        return '1688厂家直供'
      }else if (3 == this.type) {
        return '京东品质保证'
      } else {
        return '精选商品'
      }
    },
    // 免费注册
    onJump() {
      let PAGE_URL = ''
      if (process.env.NODE_ENV === 'pre') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/registerTypeSelect') : PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
      } else if (process.env.NODE_ENV === 'production') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/registerTypeSelect') : PAGE_URL = 'https://console.wanjingai.com/index'  // 退出回到商务中心index页面
      } else {
        0 == this.$tp().IS_OPEN ? this.$router.push('/registerTypeSelect') : PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
      }
      if (this.$tp().IS_OPEN == 1) window.open(PAGE_URL, '_blank')
    },
    // 登录
    onJumpLogin() {
      let PAGE_URL = ''
      if (process.env.NODE_ENV === 'pre') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
      } else if (process.env.NODE_ENV === 'production') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'https://console.wanjingai.com/index'  // 退出回到商务中心index页面
      } else {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'https://console.wanjingai.com/index'  // 退出回到商务中心index页面
      }
      if (this.$tp().IS_OPEN == 1) window.open(PAGE_URL, '_blank')
    },
    fn(v) {
      if (v.jumpUrl) {
        window.open(v.jumpUrl)
      }
    },
    getHome() {
      cateIndex().then(res => {
        this.cateUplist = res.data.data
        this.cateUplist.forEach(item => {
          let data1 = {
            isHot: 1,
            current: null,
            size: 21,
            firstCategoryId: item.oneCateId
          }
          getProductPage(data1).then(res => {
            item.brandIds = res.data.records
          })
        })
      })
    },
    // 个人中心
    userCenter() {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        this.$router.push({ path: '/user/userCenter' })
      }
    },
    // 退出
    logout() {
      let PAGE_URL = ''
      let that = this
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        localStorage.removeItem('historyList');

        if (0 == that.$tp().IS_OPEN) {
          that.$store.dispatch('FedLogOut').then(() => {
            that.$router.push('/login')
          })
        } else if (1 == that.$tp().IS_OPEN) {
          if (process.env.NODE_ENV === 'pre') {
            PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
          } else if (process.env.NODE_ENV === 'production') {
            PAGE_URL = 'https://console.wanjingai.com/index'  // 退出回到商务中心index页面
          } else {
            PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
          }
          window.location.replace(PAGE_URL)
        }

      })
    },
    getDay() {
      // 获取当前时间
      let timeNow = new Date()
      // 获取当前小时
      let hours = timeNow.getHours()
      // 设置默认文字
      let text = ``
      // 判断当前时间段
      if (hours >= 0 && hours <= 10) {
        text = `早上好，`
      } else if (hours > 10 && hours <= 14) {
        text = `中午好，`
      } else if (hours > 14 && hours <= 18) {
        text = `下午好，`
      } else if (hours > 18 && hours <= 24) {
        text = `晚上好，`
      }
      // 返回当前时间段对应的状态
      return text
    },
    getsupplyBanner() {
      supplyBanner({ type: 1 }).then(res => {
        this.bannerList = res.data
      })
    },
    getRaroduct(val) {
      let date = {
        hot: val == 3 ? 1 : null,
        news: val == 0 ? 1 : null,
        recommend: val == 2 ? 1 : null
      }
      console.log(1111111)
      getRandomProduct(date).then(res => {
        this.cutList = res.data.records
      })
    },
    //每日推荐
    getPage1() {
      let data = {
        current: null,
        size: 1000,
        isRecommend: 1  // 查询推荐
      }
      getProductPage(data).then(res => {
        this.timeList = res.data.records
      })
    },
    goSearch(firstCategory, secondCategory, thirdCategory) {
      console.log(firstCategory, secondCategory, thirdCategory)
      this.$router.push({
        path: '/search',
        query: { type: this.type, firstCategory: firstCategory, secondCategory: secondCategory, thirdCategory: thirdCategory }
      })
    },
  },
}
</script>


<style lang="scss" scoped>
.roqi {
  margin-bottom: 7px;
  .ant-btn-group {
    width: 100%;
    ::v-deep .ant-btn {
      width: 35%;
      cursor: default;
      border: 0;
      border-right: 1px solid #ccc;
      padding: 0;
    }
    ::v-deep .ant-dropdown-trigger {
      width: 65%;
      cursor: pointer;
      border: 0;
      border-left: 1px solid #ccc;
      span {
        font-weight: bold;
        font-size: 15px;
      }
    }
  }
}
.right-bottom {
  padding: 20px;
  height: 100px;
  // border-bottom: 1px solid rgb(243, 242, 242);
}

.right-center {
  text-align: center;

  p {
    font-weight: 600;
    font-size: 18px;
  }
}

.right-center:hover {
  background: rgb(238, 238, 238);
  cursor: pointer;
}

.right-top {
  position: relative;
  padding: 18px;
  overflow: hidden;
  background-color: #fbf3e2;
}

.right-flex {
  display: flex;
  flex-flow: row;
}

.right-leftbtn {
  display: inline-block;
  padding-top: 2px;
  width: 145px;
  height: 32px;
  font: 14px/26px PingFangSC-Regular;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  letter-spacing: 0;
  text-decoration: none;
  border: 1px solid #ff4000;
  background-color: #ff4000;
}

.right-rightbtn:hover {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff !important;
}

.right-rightbtn {
  display: inline-block;
  padding-top: 2px;
  width: 145px;
  height: 32px;
  font: 14px/26px PingFangSC-Regular;
  text-align: center;
  color: #ff4000;
  border-radius: 4px;
  letter-spacing: 0;
  text-decoration: none;
  border: 1px solid #ff4000;
}

.right-img {
  width: 100%;
}

.right-top-cai {
  margin-top: 10px;
  display: inline-block;
  padding: 2px 8px;
  background: #ffebe7;
  border-radius: 10px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #ff2900;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
}

.threeCate {
  margin: 5px 0;
  z-index: 1800;
  color: #666666;
  cursor: pointer;

  &:not(:last-child)::after {
    content: "";
    border-right: 1px solid #ccc;
    margin: 0 5px;
  }

  &:hover {
    color: #ff2900;
  }
}

.disc {
  display: flex;
  justify-content: left;
}

.cateFirst {
  cursor: pointer;
  text-align: center;

  &:hover {
    color: #ff2900;
  }
}

.banner1 {
  height: 501px;
  width: 2361px;
  background-position: 50%;
}

.HomeGoodsCategory {}

.xia {
  border-bottom: 1px solid #363636;
}

.topTabBar {
  // height: 56px;

  //.active span:hover {
  //  color: $color-main;
  //}
  .categoryTitle {
    cursor: pointer;
    background: #2e3353;
    color: #fff;
    font-size: 15px;
    // height: 40px;
    overflow: visible;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      // background: #f2f2f2;
    }
  }

  .categoryTabBar {
    width: 172px;
    height: 53px;
    //line-height: 53px;
    justify-content: center;
    cursor: pointer;
  }

  ul.categoryTabBar {
    li {
      // padding: 0 20px 0 20px;
      // line-height: 44px;
      font-size: 14px;
    }
  }
}

.content {
  padding-top: 0;
  // position: absolute;
  // width: 20px;
  // top: 10px;
  // left: 50%;
  // transform: translateX(-50%);
  z-index: 3;
}

@media (max-width: 1200px) {
  .main-content.content {
    width: 100%;
  }
}

ul.category {
  width: 220px;
  background-color: #fff;
  height: 368px;
  padding-left: 0;
  margin: 0;
  // border: 1px solid #fff;
  // border-top: solid 3px #fc0;
  overflow-y: scroll;
  scrollbar-width: none;
  box-shadow: 0 3px 20px rgb(9 98 234 / 10%);
  border-radius: 4px;
  display: flex;
  flex-flow: wrap;

  li {
    width: 220px;

    //height: 35px;
    z-index: 1;

    // line-height: 30px;
    &:hover>.sub_category {
      display: flex;
      color: #ff2900;
    }
  }
}

.category>li:hover {
  &:hover>.cateFirst {
    color: #ff2900;
  }
}

.sub_category {
  display: none;
  position: absolute;
  flex-wrap: wrap;
  left: 220px;
  top: 10px;
  background-color: white;
  width: 700px;
  height: 510px;
  border: 1px solid #ff4000;
  overflow-y: scroll;
  scrollbar-width: none;
  z-index: 100;

  &::-webkit-scrollbar {
    width: 0;
  }

  .ant-card {
    height: 160px;
    padding: 10px;
    margin: 20px;
  }

  .ant-card-body {
    padding: 10px;
  }

  .ant-card-hoverable:hover {
    background-color: rgba(155, 155, 155, 0.15);
  }

  ::v-deep .ant-card-meta-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
.category {
  border-radius: 4px;

  .category ul li {
    width: 220px;
    // padding-left: 20px;
    // padding-right: 30px;
    height: 35px;
    z-index: 1;
    line-height: 30px;

    &:hover>.sub_category {
      display: block;
    }
  }
}


.cateFirst:hover {
  // box-shadow: 0 3px 6px #ffedea;
  background: #ffedea;

}

.tops {
  background: #2e3353;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
}

.disc-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  // height: 40px;
  width: 100%;
}

.disc-flex:hover {
  // box-shadow: 0 3px 6px #ffedea;
  background: #ffedea;

}

.cateFirst {
  width: 100px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  padding-left: 15px;
}
.wbyc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dis {
  display: flex;
  justify-content: space-between;

  &>.dis:hover .sub_category {
    display: block;
  }

  .left {
    width: 220px;
    // box-shadow: 0 3px 3px 0 #ddd;
  }

  .cont {
    // width: 720px;
    width: 600px;
  }

  .right {
    background: #ffffff;
    width: 348px;
  }
}


.floor {
  width: 1200px;
  margin: 20px auto 0;
}

.main-content {
  width: 1200px;
  background: #f4f4f4;
}

// @media (max-width: 1270px) {
//   .main-content{
//     width: 100%;
//   }
// }
</style>
