import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from '@/store/modules/app'
import user from '@/store/modules/user'
import animation from '@/store/modules/animation'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    animation,

  },
  state: {
    names: '',
    bottom:true, //默认底部数据没有加载完
  },
  mutations: {},
  actions: {},
  getters
})
