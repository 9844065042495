<template>
  <div class="shopIndex">
    <ShopNavigation></ShopNavigation>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import ShopNavigation from '@/Layouts/components/ShopNavigation'
import Footer from '@/Layouts/components/Footer'

export default {
  name: 'ShopIndex',
  components: { ShopNavigation, Footer }
}
</script>

<style scoped>

</style>
