<template>
  <div class="top20">
    <a-row>
      <a-col :span="24" >
        <div class="cloudIS" >
          <div v-for="item in cloud" :key="item.id" class="box">
            <div class="title">{{ item.title }}</div>
            <div v-for="it in item.data" :key="it" class="box-item">
              <p>{{ it }}</p>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cloud:[
        {
          id:0,
          title:'优选云仓',
          data:[
            '贵州仁怀玖水云仓1号库',
            '昆明大当家云仓1号库',
            '长沙仓满多云仓1号库',
            '金皓阳方盛云仓一号库',
            '上海汇煜云仓1号库'
          ]
        },
        {
          id:1,
          title:'优选商家',
          data:[
            '无锡点美化妆品有限公司',
            '松原市轩宇互联农特产品有限公司',
            '昆明阿表哥商贸有限公司',
            '烟台恒富嘉供应链有限公司',
            '五常市金亮星水稻种植专业合作社'
          ]
        },
        {
          id:2,
          title:'优选服务商',
          data:[
            '北京为何何为科技有限公司',
            '海丰县速一尔快递有限公司',
            '湖南鲸鱼跨境项目管理有限公司',
            '河南九农供应链管理有限责任公司',
            '北京艾迪亚传媒科技集团有限公司'
          ]
        }
      ]
    }
  },
}
</script>

<style scoped>
.cloudIS{
  display: flex;
  justify-content: space-between;
}
.title{
  text-align: center;
  background-color: #f50;
  line-height: 50px;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.box-item{
  font-size: 16px;
  line-height: 2;
  margin-top: 10px;
  text-align: center;
  color: #000;
}
p{
  font-size: 16px;
}
.box{
    background-color: #fff;
    /* width: 386px; */
    width: 386px;
    border-radius: 5px 5px 0 0;
    padding-bottom: 30px;
}
@media screen  and (min-width:1270px) {
  .box{
    width: 30%;
  }
}
</style>
