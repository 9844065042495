<template>
  <div class="head-join">
    <div v-if="Object.keys(userInfo).length > 0" class="user_icon">
      <img
        :src="userInfo.avatar !== null && userInfo.avatar !== undefined && userInfo.avatar !== '' ? userInfo.avatar : 'https://cdn.jingpingo.com/mall/user.png'"
        alt="" srcset="">
    </div>
    <svg v-else aria-hidden="true" class="icon">
      <use xlink:href="#icon-join"></use>
    </svg>
    <div class="head-join-text mx-10 home-join">
      <a-dropdown v-model="showJoin">
        <template v-if="Object.keys(userInfo).length > 0">
          <div class="joinText ant-dropdown-link">
            <p style="color: #2c75e2;">
              你好<strong style="cursor: pointer;text-overflow: none;" @click="$router.push('/user/userCenter')">{{
                datas.businessName ? '，' + datas.businessName : '' }}</strong>
            </p>
            <p style="color: #d3d5d5;">
              个人中心 <a-icon type="down" />
            </p>
          </div>
          <div slot="overlay" class="dropdown-showJoin dropdown-div">
            <ul style="padding-left: 0;margin-bottom: 12px">
              <li class="item" style="color:#0865ea;">
                <img
                  :src="userInfo.avatar !== null && userInfo.avatar !== undefined && userInfo.avatar !== '' ? userInfo.avatar : 'https://cdn.jingpingo.com/mall/user.png'"
                  alt="" height="65" srcset="" style="border-radius: 50%;margin-right: 10px;" width="65">
                你好<strong style="cursor: pointer" @click="$router.push('/user/userCenter')">{{ datas.businessName
                }}</strong>
              </li>
            </ul>
            <ShortcutsHome :width="180" title=""></ShortcutsHome>
            <div class="login" @click="LogOut()">
              退出登录
            </div>
          </div>
        </template>
        <template v-else>
          <div class="joinText ">
            <p style="line-height: 44px;">
              <span style="cursor: pointer"
                @click="$router.push($tp().IS_OPEN == 1 ? 'user/register' : '/registerSteps')">注册</span> /
              <span style="cursor: pointer" @click="$router.push($tp().IS_OPEN == 1 ? '/user/login' : '/login')">登录</span>
            </p>
          </div>
          <div slot="overlay" class="dropdown-showJoin dropdown-div">
            <a-button style="width:100%;font-size: 16px" type="primary"
              @click="$router.push($tp().IS_OPEN == 1 ? 'user/register' : '/registerSteps')">免费注册</a-button>
            <a-button class="mt-10" style="width:100%;font-size: 16px" type="primary" @click="a">已有账号</a-button>
          </div>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/25 16:43
 * @version 1.0
 * @description
 */
import ShortcutsHome from '@/components/ShortcutsHome'
import { getUserInfoRz } from '@/api/Login'
import { mapGetters } from 'vuex'
export default {
  components: { ShortcutsHome },
  data() {
    return {
      showJoin: false,
      datas: {}
    }
  },
  computed: {
    ...mapGetters(['infoProfect', 'userInfo'])
  },
  created() {
    this.$nextTick(() => {
      // 转为宏任务对象
      setTimeout(() => {
        console.log(this.infoProfect, this.userInfo)
        if (Object.keys(this.userInfo).length == 0 || this.infoProfect === 0 || this.infoProfect === 2) {
          return
        } else {
          getUserInfoRz().then((res) => {
            this.datas = res.data
          })
        }
      }, 100)
    })

    // this.$store.dispatch('GetUserInfo').then(res => {
    //   this.userInfo = res.sysUser
    //   if (Object.keys(res.sysUser).length == 0 || res.sysUser.infoProfect === 0 || res.sysUser.infoProfect === 2) return
    //   getUserInfoRz().then(res => {
    //     this.datas = res.data
    //   })
    // })
  },
  methods: {
    LogOut() {
      this.$store.dispatch('FedLogOut').then(() => {
        this.$router.push({ path: '/login' })
      })
    },
    a() {
      this.$router.push('/login')
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  position: absolute;
  right: 10px;
  top: 75px;
  text-align: center;
  line-height: 30px;
  color: #0962ea;
  cursor: pointer;
  font-size: .7rem;
}

.head-join {
  display: flex;

  .icon {
    font-size: $iconFontSize;
  }

  .head-join-text {
    p {
      margin-bottom: 5px;
    }

    strong,
    span {
      &:hover {
        color: $color-orange;
      }
    }
  }
}

.dropdown-div {
  padding: 20px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.dropdown-showJoin {
  width: 260px !important;
}

.user_icon {
  width: 45px;
  height: 45px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
