/**
 * @author  XuHongli
 * @date  2022/8/29 9:30
 * @version 1.0
 * @description 用户路由表
 */

export const UserRouters = [
  {
    path: '/user/userCenter',
    name: 'UserCenter',
    meta: {
      title: '个人中心',
      layout: 'user'
    },
    component: () => import('@/views/User/userCenter')
  },
  {
    path: '/commodity/checkOrder',
    name: 'CheckOrder',
    meta: {
      title: '确认订单'
    },
    component: () => import('@/views/User/Order/OrderCheck')
  },
  {
    path: '/user/order',
    name: 'Order',
    meta: {
      layout: 'user',
      title: '我的订单',
    },
    component: () => import('@/views/User/Order')
  },
  {
    path: '/user/exceptionOrder',
    name: 'ExceptionOrder',
    meta: {
      layout: 'user',
      title: '异常订单',
    },
    component: () => import('@/views/User/Order/ExceptionOrder/Table')
  },
  {
    path: '/user/dropOrder',
    name: 'DropOrder',
    meta: {
      layout: 'user',
      title: '代发订单',
    },
    component: () => import('@/views/User/Order/DropOrder/index')
  },
  {
    path: '/user/orderTableDetail/:orderId(\\d+)',
    name: 'OrderTableDetail',
    meta: {
      layout: 'user',
      title: '订单详情'
    },
    component: () => import('@/views/User/Order/OrderTableDetail')
  },
  {
    path: '/user/shopLibrary',
    name: 'ShopLibrary',
    meta: {
      layout: 'user',
      title: '商品库'
    },
    component: () => import('@/views/User/ShopLibrary')
  },
  {
    path: '/user/ShopLibrary/GroupDetail/:groupId(\\d+)',
    name: 'GroupDetailTable',
    meta: {
      layout: 'user',
      title: '分组详情'
    },
    component: () => import('@/views/User/ShopLibrary/GroupDetailTable')
  },
  {
    path: '/user/order/detail/:id',
    name: 'OrderDetail',
    meta: {
      title: '订单详情',
    },
    component: () => import('@/views/User/Order/OrderDetail')
  },
  {
    path: '/user/viewed',
    name: 'Viewed',
    meta: {
      layout: 'user',
      title: '浏览记录'
    },
    component: () => import('@/views/User/Viewed')
  },
  {
    path: '/user/address',
    name: 'Address',
    meta: {
      layout: 'user',
      title: '收货地址',
    },
    component: () => import('@/views/User/Address')
  },
  {
    path: '/user/development',
    name: 'Development',
    meta: {
      layout: 'user',
      title: 'API对接'
    },
    component: () => import('@/views/User/development')
  },
  {
    path: '/user/favorite',
    name: 'Favorite',
    meta: {
      title: '我的收藏',
      layout: 'user',
    },
    component: () => import('@/views/User/Favorite')
  },
  {
    path: '/user/data/dealData',
    name: 'DealData',
    meta: {
      layout: 'user',
      title: '数据看板'
    },
    component: () => import('@/views/User/dataStatits/dealData')
  },
  {
    path: '/user/data/reaData',
    name: 'ReaData',
    meta: {
      layout: 'user',
      title: '实时预览'
    },
    component: () => import('@/views/User/dataStatits/reaData')
  },
  {
    path: '/user/setting',
    name: 'Set',
    meta: {
      title: '个人信息',
      layout: 'user',
    },
    component: () => import('@/views/User/Set/component/userInfo')
  },
  {
    path: '/user/supplyFromManufacturer',
    name: 'SupplyFromManufacturer',
    meta: {
      title: '厂家直供',
      layout: 'user'
    },
    component: () => import('@/views/User/SupplyFromManufacturer')
  },
  {
    path: '/user/handpickGoods',
    name: 'HandpickGoods',
    meta: {
      title: '精选商品',
      layout: 'user'
    },
    component: () => import('@/views/User/HandpickGoods')
  },
  {
    path: '/user/jdSelection',
    name: 'JdSelection',
    meta: {
      title: '京东直选',
      layout: 'user'
    },
    component: () => import('@/views/User/JdSelection')
  },
  {
    path: '/user/mengda',
    name: 'MengDa',
    meta: {
      title: '盟大',
      layout: 'user'
    },
    component: () => import('@/views/User/MengDa')
  },
  {
    path: '/user/authentication',
    name: 'Authentication',
    meta: {
      title: '账号认证',
      layout: 'user',
    },
    component: () => import('@/views/User/Set/component/Certification')
  },
  {
    path: '/user/security',
    name: 'Security',
    meta: {
      title: '账号安全',
      layout: 'user',
    },
    component: () => import('@/views/User/Set/component/accountSec')
  },
  // {
  //   path: '/user/development',
  //   name: 'Development',
  //   meta: {
  //     title: 'API对接',
  //     layout: 'user',
  //   },
  //   component: () => import('@/views/User/development/DevelopmentSetting')
  // },
  {
    path: '/user/general/view',
    name: 'GeneralView',
    meta: {
      title: '资金概览',
      layout: 'user',
    },
    component: () => import('@/views/User/FinancialManagement/Overview')
  },
  // 充值
  {
    path: '/user/fina/recharge',
    name: 'recharge',
    meta: {
      title: '充值',
      layout: 'user',
    },
    component: () => import('@/views/User/FinancialManagement/fina/recharge')
  },
  // 提现
  {
    path: '/user/fina/withdrawDeposit',
    name: 'withdrawDeposit',
    meta: {
      title: '提现',
      layout: 'user',
    },
    component: () => import('@/views/User/FinancialManagement/fina/withdrawDeposit')
  },
  {
    path: '/user/funding/bills',
    name: 'FundingBills',
    meta: {
      title: '资金流水',
      layout: 'user',
    },
    component: () => import('@/views/User/FinancialManagement/Blotter')
  },
  {
    path: '/user/profit',
    name: 'Profit',
    meta: {
      title: '利润明细',
      layout: 'user',
    },
    component: () => import('@/views/User/FinancialManagement/Profit')
  },
  {
    path: '/user/recharge',
    name: 'Recharge',
    meta: {
      title: '充值记录',
      layout: 'user',
    },
    component: () => import('@/views/User/FinancialManagement/Recharge')
  },
  {
    path: '/user/withdrawals',
    name: 'Withdrawals',
    meta: {
      title: '提现记录',
      layout: 'user',
    },
    component: () => import('@/views/User/FinancialManagement/Withdrawals')
  },
  // {
  //   path: '/user/settlement',
  //   name: 'settlement',
  //   meta: {
  //     title: '结算列表',
  //     layout: 'user',
  //   },
  //   component: () => import('@/views/User/FinancialManagement/Settlement')
  // },
  {
    path: '/user/invoice/list',
    name: 'InvoiceList',
    meta: {
      title: '发票列表',
      layout: 'user',
    },
    component: () => import('@/views/User/Invoice/List')
  },
  {
    path: '/user/invoice/info',
    name: 'InvoiceInfo',
    meta: {
      title: '发票信息',
      layout: 'user',
    },
    component: () => import('@/views/User/Invoice/Info')
  },
  {
    path: '/user/editPwd/:id',
    name: 'editPwd',
    meta: {
      title: '修改密码',
      layout: 'user',
    },
    component: () => import('@/views/User/Set/editPwd')
  },
  {
    path: '/user/editPhone/:id',
    name: 'EditPhone',
    meta: {
      title: '修改手机号',
      layout: 'user',
    },
    component: () => import('@/views/User/Set/editPhone')
  },
  {
    path: '/user/bypassAccount',
    name: 'bypassAccount',
    meta: {
      title: '子账号管理',
      layout: 'user',
    },
    component: () => import('@/views/User/bypassAccount/index.vue')
  }

]
